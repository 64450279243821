import {StackScreenProps} from '@react-navigation/stack'

import {LoanOfferAvailabilityStatus} from '@possible/cassandra/src/types/types.mobile.generated'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, ManageActiveLoanEvents} from 'src/lib/Analytics/app_events'
import {openContactUsForm} from 'src/lib/contactUs'
import {logErrorAndShowException} from 'src/lib/errors'
import Log from 'src/lib/loggingUtil'
import {EmitRedirectionEvent} from 'src/lib/utils/events'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {DashboardLoanAggregateStatusQueryLoanOfferInfo} from 'src/products/loans/Dashboard/DashboardLoan/queries/useDashboardLoanAggregateStatusQuery'
import {
  WhyChargedOffModalProps,
  showWhyChargedOffModal,
} from 'src/products/loans/Dashboard/WhyChargedOffModal/WhyChargedOffModal'
import {showWhyDefaultModal} from 'src/products/loans/Dashboard/WhyDefaultModal/WhyDefaultModal'
import {usePfDispatch} from 'src/store/utils'
import {wfError} from 'src/workflows/logging'
import {ClearSelectedOfferAction, SetWorkflowStateAction} from 'src/workflows/slice'

export type DashboardNavigation = StackScreenProps<
  MainStackParamList,
  'Dashboard' | 'ProductHub'
>['navigation']

export const onShowDefaultExplanation = (
  params: Pick<WhyChargedOffModalProps, 'onMakeAPayment'>,
): void => {
  TrackAppEvent(
    ManageActiveLoanEvents.default_explanation_selected,
    AppEvents.Category.ManageActiveLoan,
  )
  showWhyDefaultModal({
    onMakeAPayment: params.onMakeAPayment,
  })
}

export const onShowChargedOffExplanation = (
  params: Pick<WhyChargedOffModalProps, 'onMakeAPayment'>,
): void => {
  TrackAppEvent(
    ManageActiveLoanEvents.charged_off_explanation_selected,
    AppEvents.Category.ManageActiveLoan,
  )
  showWhyChargedOffModal({
    onMakeAPayment: params.onMakeAPayment,
  })
}

export const SelectLoanOffer = (
  loanOffers: Pick<
    DashboardLoanAggregateStatusQueryLoanOfferInfo,
    'loanOfferAvailabilityStatus' | 'offers'
  >[],
): string | null => {
  // get the first available loan offer we see
  for (const offer of loanOffers) {
    if (
      offer.loanOfferAvailabilityStatus === LoanOfferAvailabilityStatus.OfferAvailable &&
      offer.offers.length > 0
    ) {
      return offer.offers[0].id
    }
  }
  return null
}

/**
 * Handle when a user begins to reapply for a new loan AFTER they've already had their first loan.
 */
export const onReapplyForNewLoan = async (params: {
  navigation: DashboardNavigation
  dispatch: ReturnType<typeof usePfDispatch>
  shouldRedirectLoanApplyAndAcceptToWeb: boolean
  reapplyEventArgs?: object
  loanOffers: DashboardLoanAggregateStatusQueryLoanOfferInfo[]
}): Promise<void> => {
  try {
    const {
      navigation,
      dispatch,
      shouldRedirectLoanApplyAndAcceptToWeb,
      reapplyEventArgs = {},
    } = params
    // reapply_for_loan_selected event is specific to re-applying
    TrackAppEvent(
      ManageActiveLoanEvents.reapply_for_loan_selected,
      AppEvents.Category.ManageActiveLoan,
      reapplyEventArgs,
    )
    if (shouldRedirectLoanApplyAndAcceptToWeb) {
      EmitRedirectionEvent('workflows')
    } else {
      try {
        const loanOfferId = SelectLoanOffer(params.loanOffers)
        if (loanOfferId) {
          await dispatch(ClearSelectedOfferAction()).unwrap()
          await dispatch(
            SetWorkflowStateAction({
              selectedOffer: {offerId: loanOfferId, metFrontEndPreReqs: []},
            }),
          ).unwrap()
          navigation.push('OfferApplicationWorkflow', {
            offerId: loanOfferId,
            screen: 'Loading',
          })
        } else {
          throw new Error('No loan offers available')
        }
      } catch (e) {
        const error = e instanceof Error ? e : new Error(String(e))
        wfError(error, 'Failed to select loan offer from loan dashboard')
        loanDashboardLogErrorShowException(error, 'Failed to select loan offer from loan dashboard')
      }
    }
  } catch (e) {
    void loanDashboardLogErrorShowException(
      e instanceof Error ? e : new Error(String(e)),
      'DashboardLoan.utils onReapplyForNewLoan() failed to send user to reapply screen',
    )
  }
}

/**
 * Open the contact us form to let users contact us for any reason.
 */
export const onContactUs = (params: {navigation: DashboardNavigation}): void => {
  TrackAppEvent(ManageActiveLoanEvents.contact_us_selected, AppEvents.Category.ManageActiveLoan)
  openContactUsForm(params.navigation)
}

/**
 * Send user to the account management screen to relink their bank account.
 */
export const onManagePaymentAccounts = (params: {navigation: DashboardNavigation}): void => {
  const {navigation} = params
  navigation.navigate('AccountManagementV2')
}

/**
 * Let the user update their loan payment dates schedule. If an upgrade is available it will send them
 * to that UX, otherwise they're sent to the scheduling screen.
 */
export const onUpdateLoanPaymentDates = (params: {
  navigation: DashboardNavigation
  multiPaymentUpgradeAvailable: boolean
  loanId?: string
}): void => {
  // refactored from loanCardUtils onReschedule()
  const {multiPaymentUpgradeAvailable: isMultiPaymentUpgradeAvailable, navigation, loanId} = params
  TrackAppEvent(
    ManageActiveLoanEvents.reschedule_payments_selected,
    AppEvents.Category.ManageActiveLoan,
  )
  if (isMultiPaymentUpgradeAvailable) {
    try {
      navigation.push('UpgradeToInstallment', {isFromDashboard: true})
    } catch (e) {
      void loanDashboardLogErrorShowException(
        e instanceof Error ? e : new Error(String(e)),
        'DashboardLoan.utils, onUpdateLoanPaymentDates(), navigation to UpgradeToInstallment failed',
      )
    }
  } else {
    try {
      if (!loanId) {
        throw new Error('loanId not available')
      }
      navigation.push('SelectPaymentToReschedule', {loanId: loanId})
    } catch (e) {
      void loanDashboardLogErrorShowException(
        e instanceof Error ? e : new Error(String(e)),
        'DashboardLoan.utils, onUpdateLoanPaymentDates(), navigation to SelectPaymentToReschedule failed',
      )
    }
  }
}

/**
 * Send user to the documents history page to view their loan history.
 */
export const onViewLoanHistory = (params: {navigation: DashboardNavigation}): void => {
  TrackAppEvent(ManageActiveLoanEvents.view_history_selected, AppEvents.Category.ManageActiveLoan)
  const {navigation} = params
  navigation.navigate('DocumentsHistory')
}

const addLoanDashboardIdentifierToError = (e: Error): Error => {
  const modifiedError = new Error(`${e.message} - Loans Dashboard`)
  modifiedError.stack = e.stack
  return modifiedError
}

/**
 * Log error and show an exception for all dashboard related errors.  Includes standardized log prefix.
 */
export const loanDashboardLogErrorShowException = (e: unknown, context?: string): void => {
  void logErrorAndShowException(
    addLoanDashboardIdentifierToError(e instanceof Error ? e : new Error(String(e))),
    context,
  )
}

/**
 * Log errors related to the dashboard. Includes standardized log prefix.
 */
export const loanDashboardLogError = (e: Error, msg?: string): void => {
  Log.error(addLoanDashboardIdentifierToError(e), msg)
}
