import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UserSetResidenceStateMutationVariables = Types.Exact<{
  state: Types.StateCodes
}>

export type UserSetResidenceStateMutation = {
  __typename?: 'Mutation'
  userSetResidenceState: boolean
}

export const UserSetResidenceStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {kind: 'Name', value: 'UserSetResidenceState'},
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {kind: 'Variable', name: {kind: 'Name', value: 'state'}},
          type: {
            kind: 'NonNullType',
            type: {kind: 'NamedType', name: {kind: 'Name', value: 'StateCodes'}},
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'userSetResidenceState'},
            arguments: [
              {
                kind: 'Argument',
                name: {kind: 'Name', value: 'state'},
                value: {kind: 'Variable', name: {kind: 'Name', value: 'state'}},
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserSetResidenceStateMutation, UserSetResidenceStateMutationVariables>
