import React, {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'
import timer from 'react-native-timer'
import {useNavigation} from '@react-navigation/native'

import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import HookForm, {FieldVariants} from 'src/designSystem/components/atoms/HookForm/HookForm'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {
  ButtonLockupProps,
  ButtonLockupPropsSecondary,
} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import {ShowLightbox} from 'src/designSystem/components/organisms/Lightbox'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import Snackbar from 'src/lib/Snackbar'
import {openContactUsForm} from 'src/lib/contactUs'
import {getHasErrorsOrMissingValues, validLengthNumeral} from 'src/lib/utils/formValidationUtil'
import CodeSentConfirmation from 'src/products/loans/PhoneConfirmation/CodeSentConfirmation'
import {
  EMAIL,
  PHONE,
  VerificationMethod,
  VerificationStackNavigationPropType,
} from 'src/products/loans/PhoneConfirmation/PhoneConfirmation.types'
import {waitTime} from 'src/products/loans/PhoneConfirmation/ResendWaitingTime'
import {UserMfaDeliveryMedium} from '@possible/cassandra/src/types/types.public.generated'

export type FormData = {
  verificationCode: number
}

type Props = {
  resendAction: (UserMfaDeliveryMedium) => Promise<boolean>
  submitAction: (string) => Promise<void>
  parentBusy?: boolean
  formattedPhoneNumber?: string
  email?: string
  verificationMethod: VerificationMethod
  testID?: string
}

const timerId = 'resendFrequency'
const resendFrequency = waitTime * 1000 * 60
const verificationCodeLength = 4

const GenericVerification: FC<Props> = (props) => {
  const {
    resendAction,
    submitAction,
    parentBusy: isParentBusy = false,
    formattedPhoneNumber,
    email,
    verificationMethod,
    testID,
  } = props
  const navigation = useNavigation<VerificationStackNavigationPropType>()

  const [isBusy, setIsBusy] = useState(false)
  const [isResendBusy, setIsResendBusy] = useState(false)
  const [isShowingConfirmation, setIsShowingConfirmation] = useState(false)
  const [doesBlockResend, setDoesBlockResend] = useState(false)
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm<FormData>({mode: 'all'})
  const {t} = useTranslation(['PhoneConfirmation', 'Common'])

  useEffect(() => {
    return () => timer.clearTimeout(timerId)
  }, [])

  const closeConfirmation = (): void => {
    setIsShowingConfirmation(false)
  }

  const actionInProgress = (): boolean => isBusy || isResendBusy || isParentBusy

  const getVerificationMethod = (
    phoneMethod: UserMfaDeliveryMedium,
  ): UserMfaDeliveryMedium | 'EMAIL' => {
    return verificationMethod === PHONE ? phoneMethod : EMAIL
  }

  const showConfirmation = (phoneMethod: UserMfaDeliveryMedium): void => {
    const method = getVerificationMethod(phoneMethod)
    return ShowLightbox(
      <CodeSentConfirmation
        navigation={navigation}
        method={method}
        dismissCallback={closeConfirmation}
      />,
      true,
    )
  }

  const timerCallback = (): void => {
    setDoesBlockResend(false)
  }

  const showConfirmationIfSuccessful = (
    method: UserMfaDeliveryMedium,
    successful: boolean,
  ): void => {
    if (!isShowingConfirmation && successful) {
      setIsShowingConfirmation(true)
      showConfirmation(method)
    }
  }

  const onResend = async (method: UserMfaDeliveryMedium): Promise<void> => {
    if (!doesBlockResend) {
      setDoesBlockResend(true)
      setIsResendBusy(true)
      timer.setTimeout(timerId, () => timerCallback(), resendFrequency)

      const isSuccessful = await resendAction(method)
      showConfirmationIfSuccessful(method, isSuccessful)

      setIsResendBusy(false)
    } else {
      Snackbar.info({
        title: t('PleaseWaitTimeBeforeResending', {count: waitTime}),
        duration: Snackbar.LENGTH_LONG,
      })
    }
  }

  const onPress = async (data): Promise<void> => {
    setIsBusy(true)
    await submitAction(data.verificationCode)
    setIsBusy(false)
  }

  const isCodeValid = (newCode): boolean => {
    return validLengthNumeral(verificationCodeLength, newCode)
  }

  const getDisableSubmit = (): boolean => {
    return getHasErrorsOrMissingValues(errors, watch) || actionInProgress()
  }

  const mainBody = (): string => {
    if (verificationMethod === PHONE) {
      if (!formattedPhoneNumber) {
        return t('EnterCode')
      } else {
        return t('WeJustSentYouATextMessage', {phoneNumber: formattedPhoneNumber})
      }
    } else {
      return email ? t('WeJustSentYouAnEmail', {email}) : t('WeJustSentYouAnEmailGeneric')
    }
  }

  const fallbackCopy = (
    <Box marginTop={'small'} gap={'tiny'} align={'center'}>
      <PFText variant={'p_sm'} color={NamedColors.SILVER}>
        {t('PhoneConfirmation:TextMessageNotComingThrough')}
      </PFText>
      {verificationMethod === PHONE ? (
        <SvgLink
          disabled={actionInProgress()}
          linkIcon={'internal'}
          linkIconSize={'little'}
          linkText={t('Common:GetCodeViaEmail')}
          linkType={'inline'}
          onPress={(): Promise<void> => onResend(UserMfaDeliveryMedium.Email)}
          textVariant={'p_sm'}
        />
      ) : (
        <SvgLink
          disabled={actionInProgress()}
          linkIcon={'internal'}
          linkIconSize={'little'}
          linkText={t('Common:GetCodeViaText')}
          linkType={'inline'}
          onPress={(): Promise<void> => onResend(UserMfaDeliveryMedium.Sms)}
          textVariant={'p_sm'}
        />
      )}
      <SvgLink
        disabled={actionInProgress()}
        linkIcon={'internal'}
        linkIconSize={'little'}
        linkText={t('Common:GetAPhoneCall')}
        linkType={'inline'}
        onPress={(): Promise<void> => onResend(UserMfaDeliveryMedium.Voice)}
        textVariant={'p_sm'}
      />
      <SvgLink
        linkIcon={'internal'}
        linkIconSize={'little'}
        linkText={t('Common:ContactUsLowercaseU')}
        linkType="inline"
        onPress={(): void => {
          TrackAppEvent(
            AppEvents.Name.enter_verification_code_contact_us_selected,
            AppEvents.Category.Activation,
          )
          openContactUsForm(navigation)
        }}
        textVariant={'p_sm'}
      />
    </Box>
  )
  const verificationValidation = (value): string | undefined =>
    !isCodeValid(value) ? t('verificationCodeNumeric') : undefined
  const formProps = {
    name: 'verificationCode',
    field: FieldVariants.TextField,
    rules: {
      required: t('verificationCodeRequired'),
      minLength: {
        value: verificationCodeLength,
        message: t('verificationCodeMinimum', {verificationCodeLength}),
      },
      validate: verificationValidation,
    },
  }

  const primary = {
    onPress: handleSubmit(onPress),
    text: t('SubmitVerificationCode'),
    disabled: getDisableSubmit(),
    loading: isBusy || isParentBusy,
    testID: 'Verification-Submit-Button',
  }

  const secondary: ButtonLockupPropsSecondary = {
    buttonText: t('Common:ResendMyCode'),
    size: 'medium',
    onPress: () => {
      void onResend(UserMfaDeliveryMedium.Sms)
    },
    color: 'link',
    testID: 'Verification-Resend-Button',
  }

  const buttonProps: ButtonLockupProps = {
    type: 'doubleButton',
    primary,
    secondary,
  }

  return (
    <Page
      variant={'generic'}
      smallTopGap={true}
      buttonProps={buttonProps}
      title={t('EnterYourVerificationCode')}
      titleTextAlignment={'left'}
      description={mainBody()}
      enabledKeyboardAvoidingView
      testID={testID ?? 'Generic-Verification'}
    >
      <Box gap="medium">
        <Box justify={'center'} boxStyle={style.container}>
          <HookForm control={control} errors={errors}>
            <PFTextInput
              formProps={formProps}
              testID={'input_phone_verification_code'}
              returnKeyType="done"
              keyboardType="phone-pad"
              label={t('MyVerificationCode')}
              textContentType="oneTimeCode"
              maxLength={verificationCodeLength}
            />
          </HookForm>
        </Box>
      </Box>
      {fallbackCopy}
    </Page>
  )
}

export {GenericVerification}

const style = StyleSheet.create({
  container: {
    flex: 1,
  },
})
