import React, {ReactNode, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigation} from '@react-navigation/native'
import {Image, StyleSheet} from 'react-native'

import {ampli, RegistrationWaitlistedProperties} from 'src/lib/Analytics/ampli'
import {CardTileBase, BaseCardProps} from 'src/products/card/LoanDash/CardTileBase'
import Log from 'src/lib/loggingUtil'
import {
  SetAmplitudeUserProperties,
  SetBrazeUserProperties,
  TrackAppEvent,
} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {NamedColors} from 'src/designSystem/colors'
import {ButtonLink} from 'src/designSystem/components/atoms/ButtonLink/ButtonLink'
import {PushWebPageOnStack} from 'src/navigation/NavHelper'
import {possibleCardOverview} from 'src/navigation/WebLinks'
import {setUserProperty} from 'src/api/lib/UserProperties/UserProperties.utils'
import {RegisteredForWaitListPropertyName} from 'src/products/loans/StateSelector/UnsupportedStateWaitListConstants'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import SingleCardImage from 'src/assets/images/SingleCardImage.png'
import TwoCardsImage from 'src/assets/images/card-landing-splash.png'

export type CardWaitListTileProps = Omit<BaseCardProps, 'navigation' | 'loading'> & {
  variant?: 'location' | 'quota'
}
export const CardWaitListTile = ({
  tileMarginHorizontal,
  tileRadius,
  variant = 'location',
}: CardWaitListTileProps): ReactNode => {
  usePageViewedAnalytics({
    eventName: CardEvents.card_waiting_list_dashboard_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const navigation = useNavigation()
  const [isOnWaitList, setIsOnWaitList] = useState(false)
  const [wasCtaInvoked, setWasCtaInvoked] = useState(false)
  const {t} = useTranslation('CardDashboard')

  const handleOnPressJoin = useCallback(async () => {
    if (wasCtaInvoked) return

    setWasCtaInvoked(true)

    try {
      await setUserProperty('card_wait_list', {registered_for_wait_list: true})
    } catch (e) {
      Log.warn('failed to add use to Card Wait List from Dashboard', e)
      return
    }

    SetAmplitudeUserProperties(RegisteredForWaitListPropertyName, 'true')
    TrackAppEvent(AppEvents.Name.card_waiting_list_dashboard_registered, AppEvents.Category.Card)
    SetBrazeUserProperties(RegisteredForWaitListPropertyName, true)

    const waitlistProps: RegistrationWaitlistedProperties = {
      waitlist_reason: 'us_state_ineligible',
    }
    try {
      ampli.registrationWaitlisted(waitlistProps)
    } catch (e) {
      Log.warn('failed to send registrationWaitlisted event to Ampli', e)
    }
    setIsOnWaitList(true)
  }, [wasCtaInvoked])

  const handleOnPressLearnMore = (): void => {
    TrackAppEvent(AppEvents.Name.card_waiting_list_dashboard_support, AppEvents.Category.Card)
    PushWebPageOnStack(navigation, {uri: possibleCardOverview})
  }

  const bulletPoints = [
    'CardWaitlistContentBullet1',
    'CardWaitlistContentBullet2',
    'CardWaitlistContentBullet3',
  ].map((key) => (
    <PFText variant={'p'} textAlign={'left'} color={NamedColors.WHITE} key={key}>
      {t(key)}
    </PFText>
  ))
  let tileProps

  if (!isOnWaitList && variant === 'location') {
    tileProps = {
      buttonText: 'CardWaitListCTA',
      content: <Box>{bulletPoints}</Box>,
      onPress: handleOnPressJoin,
      titleText: 'CardWaitListTitle',
      pillText: 'ComingSoon',
      pillColor: NamedColors.LIME,
      legalText: 'ApplyNowLegalContent',
      img: <Image source={TwoCardsImage} resizeMode={'contain'} style={styles.image} />,
      titleColor: NamedColors.WHITE,
      bodyColor: NamedColors.WHITE,
    }
  } else if (!isOnWaitList && variant === 'quota') {
    tileProps = {
      buttonText: 'CardWaitListCTA',
      content: (
        <Box>
          <PFText variant={'p'} textAlign={'left'} color={NamedColors.WHITE}>
            {t('CardWaitlistContentQuota')}
          </PFText>
        </Box>
      ),
      onPress: handleOnPressJoin,
      titleText: 'CardWaitListTitleQuota',
      legalText: 'ApplyNowLegalContent',
      img: <Image source={SingleCardImage} resizeMode={'contain'} style={styles.image} />,
      titleColor: NamedColors.WHITE,
      bodyColor: NamedColors.WHITE,
    }
  } else {
    tileProps = {
      backgroundColor: NamedColors.ASH,
      content: (
        <Box align={'start'}>
          <ButtonLink
            buttonLinkVariant={'default'}
            onPress={handleOnPressLearnMore}
            textVariantOverride={'p_lg'}
            size={'large'}
            iconName={'arrowRight'}
          >
            {t('LearnMore')}
          </ButtonLink>
        </Box>
      ),
      titleText: 'OnWaitList',
    }
  }

  return (
    <CardTileBase
      loading={false}
      tileMarginHorizontal={tileMarginHorizontal}
      tileRadius={tileRadius}
      backgroundColor="primary"
      {...tileProps}
    />
  )
}

const styles = StyleSheet.create({
  image: {
    width: 130,
    height: 122,
  },
})
