import {PendingURAType} from 'src/lib/ura/types'

export const URA_UPDATE = 'URA_UPDATE'

/**
 * Receive and update the list of pending URAs from the back end.
 */
export function URAUpdateAction(pendingUras: PendingURAType[]): {
  type: typeof URA_UPDATE
  pendingUras: PendingURAType[]
} {
  return {type: URA_UPDATE, pendingUras}
}
