import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {InstallmentPayment} from '@possible/cassandra/src/types/types.mobile.generated'
import {NamedColors} from 'src/designSystem/colors'
import Box, {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import CalendarDateCircle, {
  CalendarDateCircleProps,
} from 'src/designSystem/components/atoms/CalendarDateCircle/CalendarDateCircle'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Separator from 'src/designSystem/components/atoms/Separator/Separator'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {
  LedgerItem,
  LedgerItemProps,
} from 'src/products/card/components/molecules/LedgerItem/LedgerItem'

export type PayOverTimeInstallmentTileProps = Pick<
  InstallmentPayment,
  | 'amountWithoutFees'
  | 'fees'
  | 'pendingAmount'
  | 'remainingDue'
  | 'overdue'
  | 'paidOff'
  | 'dueAt'
  | 'paidAmount'
> & {
  onPressPendingPayment: () => void
}

const PayOverTimeInstallmentTile: FC<PayOverTimeInstallmentTileProps> = (props) => {
  const {
    amountWithoutFees,
    dueAt,
    fees,
    pendingAmount,
    overdue: isOverdue,
    onPressPendingPayment,
    paidAmount,
    paidOff: isPaidOff,
    remainingDue,
  } = props
  const {t} = useTranslation('CardPayOverTimeScheduleTemplate')

  const ledgerItems: LedgerItemProps[] = [
    {
      title: isOverdue ? t('missedPayment') : t('installment'),
      amount: convertToDollarAmt(amountWithoutFees),
      amountVariant: 'p',
    },
    {
      title: t('membership'),
      amount: convertToDollarAmt(fees),
      amountVariant: 'p',
    },
    {
      title: t('paymentPending'),
      amount: convertToDollarAmt(Number(pendingAmount) * -1),
      onPress: onPressPendingPayment,
      amountVariant: 'p',
    },
    {
      title: t('paid'),
      amount: convertToDollarAmt(Number(paidAmount) * -1),
      amountVariant: 'p',
    },
    {
      title: t('totalDue'),
      amount: convertToDollarAmt(remainingDue),
      amountVariant: 'p_semibold',
    },
  ]

  const getCalendarDateCircleVariant = (): CalendarDateCircleProps['variant'] => {
    let variant: CalendarDateCircleProps['variant'] = 'info'

    if (isPaidOff) {
      variant = 'success'
    }

    if (isOverdue) {
      variant = 'warning'
    }

    return variant
  }

  return (
    <Box {...boxProps}>
      <Box direction="row" justify="start">
        <CalendarDateCircle
          date={dueAt ?? ''}
          active={true}
          variant={getCalendarDateCircleVariant()}
        />
        <Box width={'80%'} padding="small">
          {isPaidOff ? (
            <Box justify="between" direction="row">
              <PFText variant="p_semibold">{t('autopayComplete')}</PFText>
              <PFText variant="p_semibold" color={NamedColors.LIME}>{`\u2713`}</PFText>
            </Box>
          ) : (
            <PFText variant="p_semibold">{t('autopayScheduled')}</PFText>
          )}
          <Box paddingTop={'tiny'} />
          <Separator />
          {ledgerItems
            .filter((item) => item.amount !== '$0.00' || item.title === t('totalDue'))
            .map(({title, ...props}) => (
              <LedgerItem
                title={title}
                key={title}
                titleTextVariant={'p'}
                amountColor={NamedColors.BLACK}
                {...props}
              />
            ))}
        </Box>
      </Box>
    </Box>
  )
}

export {PayOverTimeInstallmentTile}

const boxProps: BoxProps = {
  elevation: 4,
  radius: 'little',
  background: 'white',
  padding: 'small',
  boxStyle: {overflow: 'hidden'},
  gap: 'none',
}
