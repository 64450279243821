import {StackScreenProps} from '@react-navigation/stack'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {PaymentFlow} from 'src/products/card/PaymentMethods/types'
import {useEnablePayOverTime} from 'src/products/card/PaymentMethods/useEnablePayOverTime'
import {getPaymentMethodAccount} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, CardEvents} from 'src/lib/Analytics/app_events'
import {logErrorAndShowException} from 'src/lib/errors'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import {CardPayOverTimeDetailsTemplate} from 'src/products/card/PayOverTime/CardPayOverTimeDetails/CardPayOverTimeDetailsTemplate/CardPayOverTimeDetailsTemplate'
import {CardPayOverTimeDetailsQueryDocument} from 'src/products/card/PayOverTime/CardPayOverTimeDetails/CardPayOverTimeDetailsQuery.gqls'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {CardPayOverTimeDetailsMutationDocument} from 'src/products/card/PayOverTime/CardPayOverTimeDetails/CardPayOverTimeDetailsMutation.gqls'
import {useLinkPaymentMethod} from 'src/products/card/PaymentMethods/useLinkPaymentMethod'
import {useCards} from 'src/products/card/hooks/useCards'
import {convertToDollarAmt} from 'src/lib/utils/numberUtil'
import {useDefaultPaymentAccount} from 'src/products/card/PaymentMethods/UseDefaultPaymentAccount/useDefaultPaymentAccount'

type Props = StackScreenProps<MainStackParamList>

const CardPayOverTimeDetailsContainer: React.FC<Props> = (props) => {
  const {navigation} = props
  usePageViewedAnalytics({
    eventName: CardEvents.card_over_time_page_viewed,
    eventCategory: AppEvents.Category.Card,
  })
  const {t} = useTranslation('CardPayOverTimeDetails')

  /* These fields are currently difficult to query for without better fragment support in .gql
   * files. Will address these in a future refactor. */
  const {spentAmount} = useCards()
  const {defaultPaymentAccount, hasPaymentInstrument} = useDefaultPaymentAccount()

  const {
    data,
    error: queryError,
    loading: isLoadingQuery,
  } = useCassandraQuery(CardPayOverTimeDetailsQueryDocument, {fetchPolicy: 'cache-first'})

  const [cardAccountEnableAutomaticPayments] = useCassandraMutation(
    CardPayOverTimeDetailsMutationDocument,
    {
      /* This is necessary because the mutation's schema does not return a copy of the mutated
       * fields. This is a workaround to ensure the client and server state stay in sync. */
      update: (cache) => {
        cache.evict({
          id: `CardAccount:${data?.me.cardAccounts.active?.id}`,
          fieldName: 'autopayEnabled',
        })
      },
    },
  )

  const linkPaymentMethod = useLinkPaymentMethod()
  const enablePayOverTime = useEnablePayOverTime()
  const [isBusy, setBusy] = useState(false)

  async function handleOnPressPrimary(): Promise<void> {
    TrackAppEvent(CardEvents.card_over_time_page_accept_plan_cta, AppEvents.Category.Card)
    setBusy(true)
    try {
      // If the primary payment method doesn't have a payment instrument yet (is linked account)
      // make the user to enter the account + routing number to create the payment instrument
      if (!hasPaymentInstrument) {
        PushPage(navigation, 'CardVerifyBankDetails', {
          bankIdToFilter: getPaymentMethodAccount(defaultPaymentAccount)?.id,
          flow: PaymentFlow.PayOverTime,
        })
        return
      }

      if (!data?.me.cardAccounts.active?.autopayEnabled) {
        TrackAppEvent(CardEvents.enable_auto_pay_submitted, AppEvents.Category.Card, {
          source: 'PayOverTimeFlow',
        })

        /* This may or may not be 100% necessary but it ensures that whatever UX path the user
         * went down above that we have a usable payment method  😢 */
        const linkedPaymentMethod = await linkPaymentMethod(defaultPaymentAccount?.id, false)

        await cardAccountEnableAutomaticPayments({
          variables: {
            cardAccountId: data?.me.cardAccounts.active?.id ?? '',
            cardPaymentMethodId: linkedPaymentMethod?.id ?? '',
          },
        })
      }

      const params = await enablePayOverTime()
      navigation.push('PayOverTimeConfirmation', params)
    } catch (e) {
      void logErrorAndShowException(e, t('ErrorText'))
    }
    setBusy(false)
  }

  return (
    <BaseTemplate isLoading={isLoadingQuery} isError={!!queryError}>
      <CardPayOverTimeDetailsTemplate
        cardBalance={convertToDollarAmt(spentAmount)}
        ctaText={t(hasPaymentInstrument ? 'AcceptPlan' : 'CompletePaymentSetup')}
        defaultPaymentMethod={defaultPaymentAccount}
        isAutopayEnabled={!!data?.me.cardAccounts.active?.autopayEnabled}
        isLoading={isBusy}
        onPressPrimary={handleOnPressPrimary}
        paymentSchedule={
          data?.me.cardAccounts.active?.installmentPlans.potential?.installments ?? []
        }
      />
    </BaseTemplate>
  )
}

export {CardPayOverTimeDetailsContainer}
