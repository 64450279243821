import {useEffect, useRef} from 'react'
import {Moment} from 'moment'
import {DateTimePickerEvent} from '@react-native-community/datetimepicker'

import {PFDatePickerFieldProps} from 'src/designSystem/components/molecules/PFDatePickerField/PFDatePickerField.types'

const SET = 'set'
const DISMISSED = 'dismissed'

export const onSelect = (
  dateValue: moment.Moment | undefined,
  onChange: PFDatePickerFieldProps['onChange'],
): void => {
  if (onChange && dateValue) {
    //delay a bit for animation to complete, the animation closes
    //the keyboard causes problems on focus for next field
    setTimeout((): void => onChange(dateValue), 500)
  }
}

export const didComplete = (
  onChange: ((value: Moment) => void) | undefined,
  finalDate: Moment | undefined,
): void => {
  if (onChange && finalDate) {
    // delay a bit for animation to complete, the animation closes the keyboard
    // causes problems on focus for next field
    setTimeout(() => onChange(finalDate), 500)
  }
}

export const usePreviousDate = (dateValue: Moment): Moment => {
  const ref = useRef(dateValue)
  useEffect(() => {
    if (dateValue) {
      ref.current = dateValue
    }
  })
  return ref.current
}

export const isCorrectEventType = (e: DateTimePickerEvent): boolean =>
  e.type === SET || e.type === DISMISSED

/**
 * Determines date the DatePicker calendar should open to.
 * If the date picker has a date value set, the calendar should open to that date.
 * If there is no date vale set yet, the calendar should open to a date between
 * minimumDate and maximumDate, either today or the date closest if today is out of range.
 * @param args
 * {
 *    value?: Moment for existing date set for this field, if any
 *    minDate?: Date of the earliest valid date for this field, if any
 *    maxDate?: Date of the latest valid date for this field, if any
 * }
 */
export const getOpenToDate = (args: {
  value?: moment.Moment
  minimumDate?: Date
  maximumDate?: Date
}): Date => {
  const {value, minimumDate: minimumDate, maximumDate: maximumDate} = args
  if (value?.toDate) {
    return value.toDate()
  }

  // If no value is given, determine initial date calendar should open to.
  const today = new Date()

  if (maximumDate && maximumDate < today) {
    return maximumDate
  }

  if (minimumDate && minimumDate > today) {
    return minimumDate
  }

  return today
}
