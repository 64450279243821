import React, {useMemo} from 'react'
import {TFunction} from 'i18next'
import {Trans} from 'react-i18next'

import PFSvgContain from 'src/designSystem/components/atoms/PFSvg/PFSvgContain'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Coin from 'src/assets/icons/Coin.svg'
import Check from 'src/assets/icons/Check1.svg'
import {ProgressTrackerStepProps} from 'src/designSystem/components/molecules/ProgressTracker/ProgressTrackerStep'
import {NamedColors} from 'src/designSystem/colors'

const useSteps = (t: TFunction<'Advance', undefined>): ProgressTrackerStepProps[] => {
  return useMemo(() => {
    return [
      {
        key: 'MembershipMonthly',
        isComplete: true,
        label: <PFSvgContain svg={Coin} width={20} />,
        shouldAlignStepItemsCenter: true,
        title: <PFText variant="p">{t('MembershipMonthly')}</PFText>,
      },
      {
        key: 'MembershipFee',
        isComplete: true,
        label: <PFSvgContain svg={Check} width={20} />,
        shouldAlignStepItemsCenter: true,
        title: (
          <PFText variant="p">
            <Trans t={t} i18nKey={'MembershipFlatFee'}>
              Membership is a flat
              <PFText
                variant={'p_semibold'}
                color={NamedColors.PRODUCT_BLUE}
              >{` $15 monthly `}</PFText>
              —no additional fees or interest. Cancel anytime.
            </Trans>
          </PFText>
        ),
      },
    ]
  }, [t])
}

export {useSteps}
