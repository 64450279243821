import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {StackScreenProps} from '@react-navigation/stack'
import React, {FC} from 'react'
import {ApolloError} from '@apollo/client'

import Loading from 'src/designSystem/components/atoms/Loading/Loading'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents, BankAccountManagementEvents} from 'src/lib/Analytics/app_events'
import {useGetUserEnv} from 'src/lib/user/useGetUserEnv'
import {aggregatorsId} from 'src/lib/user/userEnvConsts'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {AddNewAccountToPossibleTemplate} from 'src/products/MCU/AccountManagementV2/AddNewAccount/AddNewAccountToPossibleTemplate'
import {convertAggregatorStringToType} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorHelper'
import {CanAddAdhocPaymentInstrumentDocument} from 'src/products/MCU/AccountManagementV2/AddNewAccount/AddNewAccountToPossible.gqls'
import Log from 'src/lib/loggingUtil'
import {snackbarErrorMessage} from 'src/lib/Snackbar/util'

type Props = StackScreenProps<MainStackParamList, 'AddNewAccountToPossible'>

const AddNewAccountToPossibleContainer: FC<Props> = (props: Props) => {
  const {navigation, route} = props
  const {onAddNewLinkedAccount} = route.params

  const {
    selectedData: canAddAdhocPaymentInstrument,
    loading: isLoadingCanAddAdhocPaymentInstrument,
  } = useCassandraQuery(
    CanAddAdhocPaymentInstrumentDocument,
    {
      fetchPolicy: 'network-only',
      onError: (e: ApolloError) => {
        Log.error(e, 'AccountManagementV2 failed to verify if can add adhoc payment instrument')
        snackbarErrorMessage('Failed verify if can add adhoc payment instrument')
      },
    },
    (data) => data?.me?.canAddAdhocPaymentInstrument,
  )

  const {userEnvData, isLoadingUserEnv} = useGetUserEnv()
  const isYodleeAggregator =
    convertAggregatorStringToType(userEnvData?.bankingAggregatorId) === aggregatorsId.yodlee

  const handleOnAddNewLinkedAccount = (): void => {
    TrackAppEvent(
      BankAccountManagementEvents.add_new_payment_account_submitted,
      AppEvents.Category.BankAccountManagement,
      {
        type: isYodleeAggregator ? 'link_with_yodlee' : 'link_with_plaid',
      },
    )
    onAddNewLinkedAccount()
  }

  const handleOnAddNewDebitCard = (): void => {
    TrackAppEvent(
      BankAccountManagementEvents.add_new_payment_account_submitted,
      AppEvents.Category.BankAccountManagement,
      {
        type: 'debit_card',
      },
    )
    navigation.navigate('CollectDebitCardNumberForAdhocPayment', {
      onSuccessRouteDestination: 'AccountManagementV2',
    })
  }
  const handleOnAddAccountRoutingNumber = (): void => {
    TrackAppEvent(
      BankAccountManagementEvents.add_new_payment_account_submitted,
      AppEvents.Category.BankAccountManagement,
      {
        type: 'routing_account',
      },
    )
    navigation.navigate('BankAddRoutingAndAccount', {
      onSuccessRouteDestination: 'AccountManagementV2',
    })
  }

  if (isLoadingUserEnv || isLoadingCanAddAdhocPaymentInstrument) {
    return <Loading type="loader0" size="large" />
  }

  return (
    <AddNewAccountToPossibleTemplate
      onAddNewLinkedAccount={handleOnAddNewLinkedAccount}
      onAddAccountRoutingNumber={handleOnAddAccountRoutingNumber}
      onAddNewDebitCard={handleOnAddNewDebitCard}
      useYodleeAggregator={isYodleeAggregator}
      canAddAdhocPaymentInstrument={canAddAdhocPaymentInstrument}
    />
  )
}

export {AddNewAccountToPossibleContainer}
