import React, {FC, isValidElement, ReactElement, ReactNode} from 'react'
import {StyleSheet, TouchableOpacity} from 'react-native'

import {
  alertErrorBorder,
  alertErrorTitle,
  alertInfoBorder,
  alertInfoTitle,
  alertSuccessBorder,
  alertSuccessTitle,
  alertWarningBorder,
  alertWarningTitle,
} from 'src/designSystem/semanticColors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {alertBorderWidth, alertIconHeight, alertIconWidth} from 'src/designSystem/guide'
import {Color} from 'src/designSystem/types'
import {isStringValueEmpty} from 'src/designSystem/lib/dataUtil'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {NamedColors} from 'src/designSystem/colors'

export type AlertLevel = 'info' | 'success' | 'warning' | 'error'

export type AlertProps = {
  level: AlertLevel
  title?: string
  description?: string | ReactNode
  onPress?: () => void | Promise<void>
  testID?: string
}

type AlertConfig = {
  border: Color
  titleColor: Color
  svgIcon: SvgIconProps
}

const getConfig = (level: AlertLevel): AlertConfig => {
  switch (level) {
    case 'info': {
      return {
        border: alertInfoBorder,
        titleColor: alertInfoTitle,
        svgIcon: {
          name: 'info',
          colorVariant: 'info',
        },
      }
    }
    case 'success': {
      return {
        border: alertSuccessBorder,
        titleColor: alertSuccessTitle,
        svgIcon: {
          name: 'checkWithCircle',
          colorVariant: 'success',
        },
      }
    }
    case 'warning': {
      return {
        border: alertWarningBorder,
        titleColor: alertWarningTitle,
        svgIcon: {
          name: 'warning',
          colorVariant: 'warning',
        },
      }
    }
    case 'error': {
      return {
        border: alertErrorBorder,
        titleColor: alertErrorTitle,
        svgIcon: {
          name: 'error',
          colorVariant: 'error',
        },
      }
    }
  }
}

const Alert: FC<AlertProps> = (props) => {
  const {title, description, onPress: handleOnPress, level, testID} = props
  const config = getConfig(level)
  const borderStyles = {
    width: alertBorderWidth,
    color: config.border,
  }
  const iconPadding = 12

  const getIcon = (): ReactElement => {
    return (
      <Box
        paddingVertical={iconPadding}
        paddingHorizontal={iconPadding}
        align={'center'}
        justify={'center'}
      >
        <SvgIcon
          name={config.svgIcon.name}
          colorVariant={config.svgIcon.colorVariant}
          size={'small'}
          isFilled
        />
      </Box>
    )
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <TouchableOpacity onPress={handleOnPress} disabled={!handleOnPress} testID="Alert-Touchable">
      <Box
        background={NamedColors.WHITE}
        radius={'little'}
        border={borderStyles}
        elevation={4}
        testID={testID}
      >
        <Box direction={'row'} justify={'center'} align={'start'}>
          {getIcon()}
          <Box
            paddingLeft={0}
            paddingRight="little"
            paddingVertical="little"
            gap={'tiny'}
            boxStyle={styles.contentContainer}
          >
            {!isStringValueEmpty(title) ? (
              <PFText variant={'label_md'} color={config.titleColor}>
                {title}
              </PFText>
            ) : null}
            {typeof description === 'string' && !isStringValueEmpty(description) ? (
              <PFText variant={'p_sm'} color={NamedColors.BLACK}>
                {description}
              </PFText>
            ) : (
              isValidElement(description) && description
            )}
          </Box>
          {handleOnPress ? (
            <Box
              width={alertIconWidth}
              height={alertIconHeight}
              alignSelf={'start'}
              paddingRight={'medium'}
              marginTop={12}
            >
              <SvgIcon name={'arrowRight'} colorVariant={'default'} />
            </Box>
          ) : undefined}
        </Box>
      </Box>
    </TouchableOpacity>
  )
}

export {Alert}

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
  },
})
