import {useFocusEffect} from '@react-navigation/native'
import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'
import {ImageStyle, StyleSheet, View} from 'react-native'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {smallGap} from 'src/designSystem/layout'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {SessionReplay} from 'src/lib/sessionReplay/sessionReplay'
import {ImageCapture} from 'src/lib/utils/formData'
import Image from 'src/products/general/Image/Image'
import {
  CameraAnalyticEvents,
  ImageOrientation,
} from 'src/products/general/components/organisms/CameraPage/CameraPage.types'
import {CalculateCameraImageStyle} from 'src/products/general/components/organisms/CameraPage/CameraPage.utils'

type CameraPageConfirmProps = {
  image: ImageCapture
  orientation: ImageOrientation
  submitButtonText?: string
  onConfirm: () => Promise<void>
  onRetake: () => void
  title: string
  message: string | React.ReactElement
  frameSize: {width: number; height: number}
  parentBusy: boolean
  viewedEvent: CameraAnalyticEvents['confirmPhotoViewedEvent']
}

const CameraPageConfirm: React.FC<CameraPageConfirmProps> = (props) => {
  const {t} = useTranslation(['CameraPage', 'Common'])

  const {
    title,
    message,
    image,
    orientation,
    frameSize,
    submitButtonText,
    onConfirm,
    onRetake,
    parentBusy: isParentBusy,
    viewedEvent,
  } = props

  const viewedEventName = viewedEvent?.name
  const viewedEventCategory = viewedEvent?.category
  useFocusEffect(
    useCallback(() => {
      if (viewedEventName && viewedEventCategory) {
        TrackAppEvent(viewedEventName, viewedEventCategory)
      }
    }, [viewedEventCategory, viewedEventName]),
  )

  const uri = {uri: image.dataUri ?? image.uri}
  const imageStyle: ImageStyle = {
    ...CalculateCameraImageStyle(frameSize, orientation),
    ...frameSize,
  }

  return (
    <Page
      variant="generic"
      smallTopGap
      title={title}
      description={typeof message === 'string' ? message : undefined}
      buttonProps={{
        type: 'doubleButton',
        primary: {
          text: submitButtonText ?? t('GenericSubmitButton'),
          onPress: onConfirm,
          disabled: isParentBusy,
          loading: isParentBusy,
          testID: 'CameraPageConfirm',
        },
        secondary: {
          buttonText: t('RetakePhoto'),
          onPress: onRetake,
        },
      }}
    >
      <Box flex>
        {typeof message !== 'string' && message}

        <View style={styles.imageWrapper} {...SessionReplay.privacyProps()}>
          <Image resizeMode="cover" source={uri} style={imageStyle} />
        </View>
      </Box>
    </Page>
  )
}

export default CameraPageConfirm

const styles = StyleSheet.create({
  imageWrapper: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: smallGap,
  },
})
