import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type CheckIsPlaidLayerAvailableQueryVariables = Types.Exact<{[key: string]: never}>

export type CheckIsPlaidLayerAvailableQuery = {
  __typename?: 'Query'
  checkOneClickOnboardingAvailability: {
    __typename?: 'CheckOneClickOnboardingAvailabilityResponse'
    isAvailable: boolean
  }
}

export const CheckIsPlaidLayerAvailableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'CheckIsPlaidLayerAvailable'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'checkOneClickOnboardingAvailability'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{kind: 'Field', name: {kind: 'Name', value: 'isAvailable'}}],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckIsPlaidLayerAvailableQuery,
  CheckIsPlaidLayerAvailableQueryVariables
>
