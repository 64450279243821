import React, {FC, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {StyleSheet} from 'react-native'

import Card from 'src/assets/icons/Card.svg'
import LargeArrow from 'src/assets/icons/LargeArrow.svg'
import {CardStaticAgreementType} from 'src/cassandra'
import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import IconFeatureList from 'src/designSystem/components/molecules/IconFeatureList/IconFeatureList'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {CardAcceptStaticAgreement} from 'src/products/card/Activation/CardAcceptDocument/CardAcceptStaticAgreement'
import {CardAgreementBureauTemplate} from 'src/products/card/Application/CardAgreementViewer/CardAgreementViewerTemplate/CardAgreementBureauTemplate'

type CardAgreementViewerTemplateProps = {
  onPress: () => Promise<void>
  isSubmitting: boolean
  called: boolean
  isInCardBureauFlow: boolean
}

const CardAgreementViewerTemplate: FC<CardAgreementViewerTemplateProps> = ({
  onPress,
  isSubmitting,
  called,
  isInCardBureauFlow,
}) => {
  const {t} = useTranslation(['CardAgreementViewer', 'CardPaymentAgreement, PersonalInformation'])
  const [isDocChecked, setIsDocChecked] = useState(false)

  if (isInCardBureauFlow) {
    return (
      <CardAgreementBureauTemplate
        onPress={() => onPress()}
        isSubmitting={isSubmitting}
        called={called}
      />
    )
  }

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('SubmitApplication'),
          onPress,
          loading: isSubmitting,
          disabled: !isDocChecked || called,
        },
      }}
      variant="generic"
      title={t('EarlyAccess')}
      smallTopGap
    >
      <Box boxStyle={styles.containerStyle}>
        <Box marginBottom="large">
          <IconFeatureList
            items={[
              {
                text: t('BetaLaunch'),
                icon: Card,
                iconWidth: 24,
              },
              {
                text: (
                  <Trans t={t} i18nKey="BetaLaunch2">
                    There are <PFText variant="p_semibold">limited spots available</PFText> for this
                    beta launch.
                  </Trans>
                ),
                icon: LargeArrow,
                iconWidth: 20,
              },
            ]}
          />
        </Box>

        <CardAcceptStaticAgreement
          checked={isDocChecked}
          setChecked={setIsDocChecked}
          cardStaticAgreementType={CardStaticAgreementType.BetaTestingAgreement}
          agreementName={t('CardPaymentAgreement:BetaTestAgreement')}
        />
      </Box>

      <Box marginBottom="medium" gap="small" marginTop={'small'} align={'center'}>
        <PFText
          // variant={'p'}
          variant={'p_sm'}
          color={NamedColors.SILVER}
        >
          {t('AgreeTermsOfUse')}
        </PFText>

        <PFText
          // variant={'p'}
          variant="p_sm"
          color={NamedColors.SILVER}
        >
          {t('PersonalInformation:YourLocationDisclaimer')}
        </PFText>
      </Box>
    </Page>
  )
}

const styles = StyleSheet.create({
  containerStyle: {flex: 1},
})
export {CardAgreementViewerTemplate}
