import LogRocket from 'logrocket'

import {LogRocketAbstraction} from 'src/lib/sessionReplay/LogRocket'

import {
  getLogRocketAppId,
  redactionTag,
  requestSanitizer,
  responseSanitizer,
  sessionReplayLogger,
} from 'src/lib/sessionReplay/LogRocket.utils'

/**
 * Exposes a normalized API for identifying navigation events. This uses `track` because there is
 * no Web SDK equivalent of the React Native SDK's `tagPage`.
 */
const identifyNavigationEvent: LogRocketAbstraction['identifyNavigationEvent'] = (screenName) => {
  LogRocket.track('navigation', {screenName})
}

/**
 * A wrapper around an identity method that carries and is bound to the same session replay instance
 * from `init`.
 */
const identifyUser: LogRocketAbstraction['identifyUser'] = (userId, params) => {
  LogRocket.identify(userId, params)
}

/**
 * Exposes a normalized API for initializing session replay across all platforms.
 */
const init: LogRocketAbstraction['init'] = async () => {
  const appId = await getLogRocketAppId()

  LogRocket.init(appId, {
    dom: {
      privateAttributeBlocklist: [`data-${redactionTag}`],
      textSanitizer: true,
      inputSanitizer: true,
    },
    network: {
      requestSanitizer,
      responseSanitizer,
    },
  })
}

/**
 * A centralized and normalized cross platform API for identifying elements to be redacted from
 * session replay. This can be spread on `Text`, `View`, or any `View` based element (read: most).
 * On native it uses the `nativeID` attribute. This must be configured to match the Web specific
 * `privateAttributeBlocklist` configuration within `init`.
 *
 * We can't set a type here until we get proper React Native Web types.
 */
const privacyProps: LogRocketAbstraction['privacyProps'] = () => ({
  dataSet: {[redactionTag]: true},
})

/**
 * A wrapper around a new session creation method that carries and is bound to the same session
 * replay instance from `init`.
 */
const startNewSession: LogRocketAbstraction['startNewSession'] = () => {
  try {
    LogRocket.startNewSession()
  } catch (error) {
    sessionReplayLogger(error, 'Error starting new session')
  }
}

const logRocketAbstraction: LogRocketAbstraction = {
  identifyNavigationEvent,
  identifyUser,
  init,
  privacyProps,
  startNewSession,
}

export {logRocketAbstraction as LogRocket}
