import React, {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {View, StyleSheet, ActivityIndicator} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {genericPageBackground} from 'src/designSystem/semanticColors'
import Log from 'src/lib/loggingUtil'

const minDisplayTime = 3000

//This page will submit the advance, once it is rendered.
//This is a bit of a hack to work with the current Loan Activation Workflow.
//The UX design has the Advance get activated on disbursement selection. However
//disbursement selection can be multiple steps. So we need a way to know when it's complete
//and then submit the advance.

type ActivateAdvanceTemplateProps = {
  onPreReqFulfilled: () => void | Promise<void>
  submitAdvance: (() => Promise<boolean | void>) | undefined
}

export const ActivateAdvanceTemplate: React.FC<ActivateAdvanceTemplateProps> = (props) => {
  const {onPreReqFulfilled, submitAdvance} = props
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const startTimeRef = useRef<number | null>(null) // Track the start time
  const {t} = useTranslation('Advance')

  useEffect(() => {
    startTimeRef.current = Date.now()
  }, [])

  useEffect(() => {
    if (isSubmitted) {
      return
    }

    if (!submitAdvance) {
      return
    }

    setIsSubmitted(true)

    submitAdvance()
      .then((result) => {
        if (result && startTimeRef.current) {
          const elapsedTime = Date.now() - startTimeRef.current // Calculate elapsed time
          if (elapsedTime > minDisplayTime) {
            Log.info('ActivateAdvanceTemplate :: Min display time already reached')
            void onPreReqFulfilled()
          } else {
            setTimeout(() => {
              Log.info('ActivateAdvanceTemplate :: Min display time reached, submitting advance')
              void onPreReqFulfilled()
            }, minDisplayTime - elapsedTime)
          }
        }
      })
      .catch((error) => {
        Log.error(error)
      })
  }, [submitAdvance, isSubmitted, onPreReqFulfilled, startTimeRef])

  return (
    <View style={styles.container}>
      <ActivityIndicator />
      <PFText variant="p_lg_semibold">{t('GettingYourAdvanceReady')}</PFText>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: genericPageBackground,
  },
})
