import React from 'react'
import {TouchableOpacity} from 'react-native'
import {useTranslation} from 'react-i18next'
import {StackHeaderProps} from '@react-navigation/stack'

import {Color} from 'src/designSystem/types'
import {DefaultVariantsColor} from 'src/designSystem/colors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

type Props = Pick<StackHeaderProps, 'navigation'> & {
  color?: Color
}

const HeaderCloseTextButton: React.FC<Props> = (props: Props) => {
  const {navigation, color} = props
  const {t} = useTranslation('Common')

  return (
    <TouchableOpacity
      accessibilityRole="button"
      activeOpacity={0.8}
      onPress={() => navigation.popToTop()}
      style={{alignItems: 'center'}}
      testID={'Header-Close-Text-Button-TouchableOpacity'}
    >
      <PFText
        variant={'label_md'}
        color={color ?? DefaultVariantsColor.link}
        testID={'Header-Close-Text-Button'}
      >
        {t('Close')}
      </PFText>
    </TouchableOpacity>
  )
}

export {HeaderCloseTextButton}
