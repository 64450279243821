import {CLEAR_USER_ACTION} from 'src/api/actions/actionsNames'
import * as uraActions from 'src/lib/ura/actions/uraActions'
import {PendingURAType} from 'src/lib/ura/types'

export type URAState = {
  ura: Map<string, PendingURAType>
}

const URAStateDefault: URAState = {
  ura: new Map<string, PendingURAType>(),
}

type URAAction =
  | {type: typeof CLEAR_USER_ACTION}
  | {type: typeof uraActions.URA_UPDATE; pendingUras: PendingURAType[]}

const convertPendingUrasListToMap = (
  pendingUras: PendingURAType[],
): Map<string, PendingURAType> => {
  const pendingUrasMap = new Map<string, PendingURAType>()
  pendingUras.forEach((ura) => {
    pendingUrasMap.set(ura.id, ura)
  })
  return pendingUrasMap
}

export default function reducer(state: URAState | undefined, action: URAAction): URAState {
  state = state ?? URAStateDefault

  switch (action.type) {
    case CLEAR_USER_ACTION:
      return URAStateDefault
    case uraActions.URA_UPDATE:
      return {
        ...state,
        ura: convertPendingUrasListToMap(action.pendingUras),
      }
    default:
      return state
  }
}
