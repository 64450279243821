import {useCallback} from 'react'

import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {
  findPreferredAccount as extractPreferredAccountFromAllBankAccounts,
  PreferredAccountType,
} from 'src/lib/bank/usePreferredAccount'
import {
  AdvanceUpdateRequirementsMutationDocument,
  SubscriptionAndAdvanceActivationDocument,
  SubscriptionAndAdvanceActivationQuery,
} from 'src/products/loans/LoanApprovedActivation/advanceUtils/advanceQueries.gqls'
import {
  extractPaymentMethodFromLoanData,
  LoanPaymentMethodInfo,
} from 'src/products/loans/LoanApprovedActivation/LoanApprovedActivation.utils'
import Log from 'src/lib/loggingUtil'
import {LoanActivationRequirementsUpdateInput} from 'src/cassandra'

type LoanType = NonNullable<
  SubscriptionAndAdvanceActivationQuery['me']['loans']['latestActionableLoan']
>

type AcceptSubscriptionQueryResults = {
  amountApproved?: string
  loanId?: string
  paymentMethodInfo?: LoanPaymentMethodInfo
  preferredAccount?: PreferredAccountType
  payments?: LoanType['agreement']['payments']
}

export const useAcceptSubscriptionAndAdvanceQuery = ():
  | AcceptSubscriptionQueryResults
  | undefined => {
  const {selectedData} = useCassandraQuery(SubscriptionAndAdvanceActivationDocument, {}, (data) => {
    if (
      data?.me?.loans?.latestActionableLoan?.aggregateStatus.__typename !==
      'ApprovedLoanAggregateStatus'
    ) {
      return
    }

    const amountApproved = data.me.loans.latestActionableLoan.aggregateStatus.amountApproved
    const loanId = data.me.loans.latestActionableLoan.id
    const paymentMethodInfo = extractPaymentMethodFromLoanData(data.me.paymentMethods)
    const preferredAccount = extractPreferredAccountFromAllBankAccounts(data.me.bankAccounts.all)
    const payments = data.me.loans.latestActionableLoan?.agreement?.payments
    return {
      amountApproved,
      loanId,
      paymentMethodInfo,
      preferredAccount,
      payments,
    }
  })

  return selectedData
}

type FutureBoolean = () => Promise<boolean>

export const useCompleteAdvanceRequirementMutation = (
  loanId: string | undefined,
  input: Omit<LoanActivationRequirementsUpdateInput, 'loanId'>,
): FutureBoolean => {
  const [loanActivationUpdateRequirements] = useCassandraMutation(
    AdvanceUpdateRequirementsMutationDocument,
  )

  return useCallback(async (): Promise<boolean> => {
    if (!loanId) {
      Log.error('No loanId provided')
      return false
    }

    const resp = await loanActivationUpdateRequirements({
      variables: {
        input: {...input, loanId},
      },
    })

    const isSuccess = resp.data?.loanActivationUpdateRequirements?.success
    if (resp.errors) {
      Log.error(resp.errors, 'Error accepting loan agreement')
      return false
    }
    return isSuccess || false
  }, [loanActivationUpdateRequirements, loanId, input])
}

export const useAcceptSubscriptionMutation = (loanId: string | undefined): FutureBoolean => {
  return useCompleteAdvanceRequirementMutation(loanId, {acceptSubscription: true})
}

// Updated useAcceptAdvanceMutation to use the common function
export const useAcceptAdvanceMutation = (loanId: string | undefined): FutureBoolean => {
  return useCompleteAdvanceRequirementMutation(loanId, {acceptAutoPayAgreement: true})
}
