import React, {FC} from 'react'
import {View, StyleSheet, ViewStyle} from 'react-native'

import {smallGap} from 'src/designSystem/layout'
import {success, error} from 'src/designSystem/semanticColors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {NamedColors} from 'src/designSystem/colors'

export type InfoTileHeaderSeverity = 'WARNING' | 'SUCCESS' | 'ERROR' | 'INFO'

export type InfoTileHeaderProps = {
  illustration?: React.ReactNode
  title?: string
  subTitle?: string | JSX.Element
  body?: (string | JSX.Element)[]
  severity?: InfoTileHeaderSeverity
  onPress?: () => void
}
const InfoTileHeader: FC<InfoTileHeaderProps> = (props) => {
  const {illustration, title, subTitle, body, severity} = props

  let style: ViewStyle = severity ? {borderRadius: 4, padding: smallGap} : {}
  const textColor = severity === 'ERROR' ? 'inverse' : undefined
  switch (severity) {
    case 'ERROR':
      style = {
        ...style,
        backgroundColor: error,
      }
      break
    case 'WARNING':
      style = {
        backgroundColor: NamedColors.WHITE,
        ...style,
      }
      break
    case 'SUCCESS':
      style = {
        backgroundColor: success,
        ...style,
      }
      break
    case 'INFO':
      break
    case undefined:
      break
  }

  return (
    <View style={style}>
      {illustration ? (
        <Box fill="horizontal" paddingTop="medium" paddingBottom="large" align="center">
          {illustration}
        </Box>
      ) : null}
      {title ? (
        <View>
          <PFText variant="p_lg_semibold" color={textColor}>
            {title}
          </PFText>
        </View>
      ) : null}
      {subTitle ? (
        <View style={title ? styles.betweenElementPadding : undefined}>
          {typeof subTitle === 'string' ? <PFText variant="d3">{subTitle}</PFText> : subTitle}
        </View>
      ) : null}
      {body ? (
        <View style={title || subTitle ? styles.betweenElementPadding : undefined}>
          {body?.map((text, index) => (
            <View
              style={index !== body.length - 1 && styles.textPadding}
              key={`body-item-${index + 1}`}
            >
              <PFText variant="p" color={textColor}>
                {text}
              </PFText>
            </View>
          ))}
        </View>
      ) : null}
    </View>
  )
}

export {InfoTileHeader}

const styles = StyleSheet.create({
  betweenElementPadding: {
    marginTop: smallGap,
  },
  textPadding: {
    marginBottom: smallGap / 2,
  },
})
