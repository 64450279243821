import React from 'react'
import {ViewStyle, TouchableOpacity} from 'react-native'

import {DefaultVariantsColor} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgIcon, SvgIconProps} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {
  iconColorOptions,
  SvgIconSize,
} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'
import {ButtonAction, Color, SizeVariants} from 'src/designSystem/types'
import {TextVariants} from 'src/designSystem/typography'
import {Extends} from 'src/lib/utils/typesUtil'

export type ButtonSizeType = Extends<SizeVariants, 'little' | 'small' | 'medium' | 'large'>
export const iconPositionValues = ['left', 'right'] as const
export type IconPosition = (typeof iconPositionValues)[number]

const buttonSizeTextVariantMap: {[key in ButtonSizeType]: TextVariants} = {
  little: 'p_sm',
  small: 'p_sm_semibold',
  medium: 'label_md',
  large: 'label_lg',
}
const buttonSizeIconSizeMap: {[key in ButtonSizeType]: SvgIconSize} = {
  little: 'little',
  small: 'little',
  medium: 'small',
  large: 'medium',
}

type ButtonLinkVariant = Extract<SvgIconProps['colorVariant'], 'default' | 'info' | 'white'>

export type ButtonLinkProps = React.PropsWithChildren & {
  buttonLinkVariant?: ButtonLinkVariant
  buttonStyle?: ViewStyle
  containerStyle?: ViewStyle
  disabled?: boolean
  iconName?: SvgIconProps['name']
  iconPosition?: IconPosition
  onPress: ButtonAction
  size?: ButtonSizeType
  testID?: string
  textVariantOverride?: TextVariants
}

const ButtonLink = ({
  buttonLinkVariant = 'info',
  buttonStyle = {},
  children,
  containerStyle,
  disabled,
  iconName,
  iconPosition = 'right',
  onPress,
  size = 'medium',
  testID = 'ButtonLink',
  textVariantOverride,
}: ButtonLinkProps): JSX.Element => {
  const textVariant = textVariantOverride ?? buttonSizeTextVariantMap[size]

  const computedStyle: ViewStyle = {
    alignItems: 'center',
    ...containerStyle,
  }

  const computedButtonStyle: ViewStyle = {
    ...computedStyle,
    ...buttonStyle,
  }

  const getTextColor = (): Color => {
    let computedColor = iconColorOptions[buttonLinkVariant].color

    if (size === 'little') {
      computedColor = DefaultVariantsColor.black
    }

    if (disabled) {
      computedColor = DefaultVariantsColor.textDisabled
    }

    return computedColor
  }

  let computedGapSize: SizeVariants = 'little'
  if (size === 'little' || size === 'small') {
    computedGapSize = 'tiny'
  }

  const handleOnPress = !disabled ? onPress : undefined

  return (
    <TouchableOpacity
      accessibilityRole="button"
      activeOpacity={disabled ? 1.0 : 0.8}
      style={computedButtonStyle}
      // Disabling the rule below as the onPress prop is not meant to return a promise, but in this case it needs to be allowed
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onPress={handleOnPress}
      testID={`${testID}-TouchableOpacity`}
    >
      <Box
        gap={computedGapSize}
        direction={iconPosition === 'left' ? 'row-reverse' : 'row'}
        boxStyle={computedStyle}
      >
        <PFText variant={textVariant} color={getTextColor()} testID={testID}>
          {children}
        </PFText>

        {iconName ? (
          <SvgIcon
            name={iconName}
            colorVariant={disabled ? 'inactive' : buttonLinkVariant}
            size={buttonSizeIconSizeMap[size]}
          />
        ) : null}
      </Box>
    </TouchableOpacity>
  )
}

export {ButtonLink}
