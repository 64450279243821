import React from 'react'
import {Platform, StyleSheet, View} from 'react-native'

import type {ButtonAction, Color} from 'src/designSystem/types'
import type {TextVariants} from 'src/designSystem/typography'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Separator from 'src/designSystem/components/atoms/Separator/Separator'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {Touchable} from 'src/designSystem/components/atoms/Touchable/Touchable'
import {littleGap, tinyGap} from 'src/designSystem/layout'
import {getTestID} from 'src/lib/utils/tests.utils'
import {
  iconColorOptions,
  SvgIconColorVariantNames,
} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'

/* `ValidTextVariants` map a single `SvgIcon` of `small`. Allowing more variants would require either
 * more complex mapping or refactoring various usages of `LedgerItem` into simpler more
 * specific components.
 */
const ledgerItemTextVariants = ['p', 'p_semibold', 'label_sm'] as const

type LedgerItemTextVariants = Extract<TextVariants, (typeof ledgerItemTextVariants)[number]>

type LedgerItemProps = {
  amount: string
  amountColor?: Color
  amountVariant?: LedgerItemTextVariants
  onPress?: ButtonAction
  separator?: boolean
  title: string
  titleAndIconColorVariant?: SvgIconColorVariantNames
  titleTextVariant?: LedgerItemTextVariants
}

const LedgerItem = ({
  amount,
  amountColor = 'textDisabled',
  amountVariant = 'label_sm',
  onPress: handleOnPress,
  separator,
  title,
  titleAndIconColorVariant = 'inactive',
  titleTextVariant = 'label_sm',
}: LedgerItemProps): JSX.Element => {
  const MaybeTouchable = handleOnPress ? Touchable : View

  return (
    <>
      {separator ? (
        <Box marginTop={'little'} testID={'LedgerItem-Separator'}>
          <Separator />
        </Box>
      ) : null}
      <MaybeTouchable
        {...(handleOnPress && {
          accessibilityRole: 'button',
          accessibilityLabel: title,
          android_ripple: {borderless: false}, // Adds Android ripple effect
        })}
        onPress={handleOnPress}
        style={styles.wrapper}
        testID={getTestID(title, '-Row-Id')}
      >
        <View style={styles.title}>
          <PFText
            variant={titleTextVariant}
            testID={getTestID(title, '-Lable-Id')}
            color={iconColorOptions[titleAndIconColorVariant].color}
            // eslint-disable-next-line react/jsx-handler-names -- handleOnPress is a false positive
            textProps={
              handleOnPress
                ? {
                    numberOfLines: 1, // See comment below regarding overflow protection
                    style: styles.link,
                  }
                : undefined
            }
          >
            {title}
          </PFText>
        </View>
        {handleOnPress ? (
          <View
            style={[
              styles.icon,
              titleTextVariant !== 'label_sm'
                ? styles.IconPOpticalCenteringFix
                : styles.IconLabelOpticalCenteringFix,
            ]}
          >
            <SvgIcon colorVariant={titleAndIconColorVariant} name={'info'} size={'little'} />
          </View>
        ) : null}

        <View style={styles.amount}>
          <PFText
            variant={amountVariant}
            color={amountColor}
            testID={'Balance-Id'}
            textAlign={'right'}
          >
            {amount}
          </PFText>
        </View>
      </MaybeTouchable>
    </>
  )
}

const styles = StyleSheet.create({
  icon: {
    paddingHorizontal: tinyGap,
  },
  IconLabelOpticalCenteringFix: {
    paddingTop: Platform.OS === 'android' ? 4 : 2,
  },
  IconPOpticalCenteringFix: {
    paddingTop: Platform.OS === 'android' ? 5 : 3,
  },
  link: {
    textDecorationLine: 'underline',
  },
  title: {
    /* Overflow protection:
     * This layout doesn't really support multiple lines as the icon would not be positioned
     * correctly. In the event of a longer title or translation this at least protects us from the
     * layout completely breaking. */
    flexShrink: 1,
  },
  amount: {
    flexGrow: 1,
  },
  wrapper: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: littleGap,
  },
})

export {LedgerItem, ledgerItemTextVariants as validLedgerItemVariants}
export type {LedgerItemProps}
