import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
  withDelay,
} from 'src/lib/ReactNativeReanimated'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {NameLogo} from 'src/designSystem/components/atoms/NameLogo/NameLogo'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {LandingScreenTile} from 'src/products/MCU/Landing/LandingScreenTile'
import {LandingScreenContent} from 'src/products/MCU/Landing/LandingScreen.types'
import {FixedSizeArray} from 'src/lib/utils/typesUtil'

type LandingScreenTemplateProps = {
  title: JSX.Element
  tilesContent: FixedSizeArray<LandingScreenContent, 4>
  handleNavigateToLogin: () => void
  handleNavigateToCreateAccount: () => void
}

const LandingScreenTemplate: React.FC<LandingScreenTemplateProps> = (props): JSX.Element => {
  const {
    handleNavigateToLogin: onHandleNavigateToLogin,
    handleNavigateToCreateAccount: onHandleNavigateToCreateAccount,
    title,
    tilesContent,
  } = props
  const {t} = useTranslation('OpeningLandingPage')

  const buttonProps: ButtonLockupProps = {
    type: 'doubleButton',
    primary: {
      text: t('GetStarted'),
      onPress: onHandleNavigateToCreateAccount,
      testID: 'Loan-Landing-Check-Eligibility-Button',
      mode: 'landingScreenPrimary',
    },
    secondary: {
      buttonText: t('AlreadyAMember'),
      onPress: onHandleNavigateToLogin,
      testID: 'Loan-Landing-Already-A-Member-Button',
      buttonLinkVariant: 'white',
    },
  }

  const rotation = useSharedValue(0)

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{rotateZ: `${rotation.value}deg`}],
    }
  })

  useEffect(() => {
    const animate = (): void => {
      rotation.value = withSequence(
        withDelay(1000, withTiming(0)),
        withTiming(-1, {duration: 50}),
        withRepeat(withTiming(1, {duration: 100}), 6, true),
        withTiming(0, {duration: 50}),
      )
    }

    animate()

    const intervalTime = 8000 // Total cycle time

    // Set a timer to restart the animation after 8 seconds repeatedly
    const intervalId = setInterval(() => {
      animate()
    }, intervalTime)

    // Cleanup the interval on component unmount
    return (): void => clearInterval(intervalId)
  }, [rotation])

  const renderContent = (
    <>
      <Animated.View style={animatedStyle}>
        <LandingScreenTile {...tilesContent[0]} />
      </Animated.View>
      <Box direction="row" justify="between" gap={'small'}>
        <LandingScreenTile {...tilesContent[1]} />
        <LandingScreenTile {...tilesContent[2]} />
      </Box>
      <Box>
        <LandingScreenTile {...tilesContent[3]} />
      </Box>
    </>
  )

  return (
    <Page
      title={<NameLogo isInverted />}
      variant={'generic'}
      backgroundColor="black"
      smallTopGap
      noHeaderSpacer
      buttonProps={buttonProps}
      contentBackgroundColor="black"
      safeAreaBackground="black"
    >
      <Box align="center">{title}</Box>
      {renderContent}
    </Page>
  )
}

export {LandingScreenTemplate}
