import React, {useCallback} from 'react'

import {capitalizeFirstLetter} from 'src/lib/user/utils'
import {AcceptAdvanceAutoPayAgreementsTemplate} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptAdvanceAutoPayAgreements/AcceptAdvanceAutoPayAgreementsTemplate'
import {
  useAcceptAdvanceMutation,
  useAcceptSubscriptionAndAdvanceQuery,
} from 'src/products/loans/LoanApprovedActivation/advanceUtils/advanceGqlUtils'
import {WorkflowPreReqFulfillScreenProps} from 'src/workflows/types'

export const AcceptAdvanceAutoPayAgreementsWorkflowGQLContainer: React.FC<
  WorkflowPreReqFulfillScreenProps
> = (props) => {
  const {onPreReqFulfilled} = props
  //const query = useAcceptSubscriptionAndAdvanceQuery()
  const query = useAcceptSubscriptionAndAdvanceQuery()

  const mutate = useAcceptAdvanceMutation(query?.loanId)

  const handleAcceptAutoPay = useCallback(async () => {
    const isSuccess = await mutate()
    if (!isSuccess) return

    await onPreReqFulfilled()
  }, [mutate, onPreReqFulfilled])

  return (
    <AcceptAdvanceAutoPayAgreementsTemplate
      amountApproved={query?.amountApproved}
      onAcceptAutoPay={handleAcceptAutoPay}
      paymentInfo={{
        payments: query?.payments ?? [],
        preferredAccountInstitutionName: query?.preferredAccount?.institution?.name ?? '',
        preferredAccountType: capitalizeFirstLetter(
          (query?.preferredAccount?.type ?? '').toLowerCase(),
        ),
        accountMask: query?.preferredAccount?.mask ?? '',
      }}
      loanId={query?.loanId ?? ''}
    />
  )
}
