import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {useCassandraMutation, useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {ButtonAction} from 'src/designSystem/types'
import {
  HowPaymentsWorkMutationDocument,
  HowPaymentsWorkQueryDocument,
} from 'src/products/loans/LoanApprovedActivation/HowPaymentsWork/HowPaymentsWorkGQLContainer/HowPaymentsWork.gqls'
import {HowPaymentsWorkTemplate} from 'src/products/loans/LoanApprovedActivation/HowPaymentsWork/HowPaymentsWorkTemplate/HowPaymentsWorkTemplate'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {logOfferActivationErrorAndShowException} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {usePromise} from 'src/lib/usePromise/usePromise'

type HowPaymentsWorkGQLContainerProps = {
  onContinue: NonNullable<ButtonAction>
}

const HowPaymentsWorkGQLContainer: FC<HowPaymentsWorkGQLContainerProps> = ({onContinue}) => {
  const {t} = useTranslation('HowPaymentsWork')
  const {
    data,
    error: queryError,
    loading: isLoadingQuery,
  } = useCassandraQuery(HowPaymentsWorkQueryDocument, {
    fetchPolicy: 'cache-first',
  })
  const [loanActivationUpdateRequirements] = useCassandraMutation(HowPaymentsWorkMutationDocument)

  const [handleOnContinue, {isLoading: isHandleOnContinueExecuting}] = usePromise(
    async function handleOnContinue(): Promise<void> {
      if (!data?.me.loans.latestActionableLoan?.id) {
        logOfferActivationErrorAndShowException(new Error(t('ErrorNoLoanId')))

        return
      }

      try {
        const {errors} = await loanActivationUpdateRequirements({
          variables: {loanId: data?.me.loans.latestActionableLoan.id},
        })

        if (errors && errors.length > 0) {
          throw errors[0]
        }

        await onContinue()
      } catch (error) {
        logOfferActivationErrorAndShowException(error, t('ErrorDefaultMessage'))
      }
    },
  )
  return (
    <BaseTemplate
      isError={!!queryError}
      isLoading={isLoadingQuery}
      pageTitle={t('MainTitle')}
      showTileBorder={false}
      testID={'HowPaymentsWorkGQLContainer'}
    >
      <HowPaymentsWorkTemplate
        isSubmitting={isHandleOnContinueExecuting}
        onContinue={async (): Promise<void> => {
          await handleOnContinue()
        }}
      />
    </BaseTemplate>
  )
}

export {HowPaymentsWorkGQLContainer}
