import React from 'react'
import {useTranslation} from 'react-i18next'

import {TransactionTileItem} from 'src/designSystem/components/organisms/TransactionsTile/TransactionsTile.utils'
import {
  TransactionsTile,
  TransactionsTileProps,
} from 'src/designSystem/components/organisms/TransactionsTile/TransactionsTile'
import {
  convertLoanPaymentsAndDisbursementToTransactionsItems,
  convertMemberhipPaymentsToTransactionItems,
  sortTransactionsByDate,
} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile.utils'
import {LoanHistoryTileProps} from 'src/products/loans/Dashboard/LoanHistoryTile/LoanHistoryTile.types'
import {useChaosModeRenderFailure} from '@possible/chaos'
import {usePfDispatch} from 'src/store/utils'
import {isAdvanceVariant} from 'src/lib/advance/CashAdvanceUtils'

/**
 * Tile to display transactions history (deposit & payments) of a loan.
 * @example <LoanHistoryTile loanPayments={[]} loanDisbursement={...} hasPreviousLoan={false} onViewLoanHistory=(() => {}}/>
 */
export const LoanHistoryTile: React.FC<LoanHistoryTileProps> = (props: LoanHistoryTileProps) => {
  const {t} = useTranslation('DashboardLoanHistoryTile')
  const dispatch = usePfDispatch()

  useChaosModeRenderFailure({
    componentName: 'LoanHistoryTile',
    dispatch,
  })
  const {
    hasPreviousLoan,
    loanPayments,
    loanDisbursement,
    onViewLoanHistory,
    testID,
    loanTypeVariant,
    subscriptions,
  } = props

  const membershipPaymentItems = convertMemberhipPaymentsToTransactionItems(
    subscriptions?.current?.payments?.all,
    t,
  )

  const transactionItems: TransactionTileItem[] =
    convertLoanPaymentsAndDisbursementToTransactionsItems({
      loanPayments,
      loanDisbursement,
      loanTypeVariant,
      t,
    })

  if (membershipPaymentItems && membershipPaymentItems.length > 0) {
    // resort transactionItems to include membership payments chronologically
    transactionItems.push(...membershipPaymentItems)
  }

  const transactionsTileProps: TransactionsTileProps = {
    title: t('HistoryTitle'),
    transactions: sortTransactionsByDate(transactionItems),
    showSeeMoreBtn: hasPreviousLoan,
    onViewMore: onViewLoanHistory,
    onViewMoreBtnText: isAdvanceVariant(loanTypeVariant)
      ? t('SeeFullHistory')
      : t('SeeFullLoanHistory'),
    testID,
  }

  return <TransactionsTile {...transactionsTileProps} />
}
