export const possibleWebsiteURI = 'https://www.possiblefinance.com'
export const possibleSupportURI = 'https://support.possiblefinance.com/hc/en-us'

export function possibleLegalURI(): string {
  return `${possibleWebsiteURI}/legal-resources/`
}

export function possibleTOSURI(): string {
  return `${possibleWebsiteURI}/tos/`
}

export function possiblePrivacyURI(): string {
  return `${possibleWebsiteURI}/privacy-policy/`
}

export function possibleStateLicensesURI(): string {
  return `${possibleWebsiteURI}/state-licenses/`
}

export function possibleElectronicCommunicationURI(): string {
  return `${possibleWebsiteURI}/electronic-communications/`
}

export function possibleLMembershipURI(): string {
  //This is a place holder, once the membership agreement is live, we will update this
  return `${possibleWebsiteURI}/membership-terms-and-conditions/`
}

export const possibleCreditApprovalDisclosureURI = `${possibleWebsiteURI}/prescreen`
export const possibleSupportCameraPermission = `${possibleSupportURI}/articles/9473973190285-Resolving-Camera-Issues-and-Other-Errors#Granting%20Permission%20for%20Camera`
export const possibleWhyRejected = `${possibleSupportURI}/articles/9508399404941-Denials#What_are_common_loan_application_denial_reasons?`
export const possibleHowSDBRestrictionsWork = `${possibleSupportURI}/articles/9508399404941-Denials#Why_was_my_loan_application_denied_for_a_State_Database_Restriction?`
export const possibleSsnURI = `${possibleSupportURI}/articles/9512046771213-Applying-for-a-Loan#Application%20Requirements`
export const possibleCardOverview = `${possibleSupportURI}/articles/9542624474381-About-The-Possible-Card#The%20Possible%20Card`

export const coastalPrivacyPolicyURI = 'https://www.coastalbank.com/privacy-notice.html'
