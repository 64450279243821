import React, {FC} from 'react'
import {StyleSheet, View} from 'react-native'
import {StackScreenProps} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {useCassandraQuery} from '@possible/cassandra/src/utils/hooks'
import {smallGap} from 'src/designSystem/layout'
import {AppEvents} from 'src/lib/Analytics/app_events'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Page from 'src/designSystem/components/organisms/Page/Page'
import TileListView from 'src/products/loans/components/molecules/TileListView'
import Documents from 'src/products/loans/LoanHistory/Documents'
import {formatShortNumericDate} from 'src/lib/time_util'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {
  LoanHistoryDocument,
  LoanHistoryQuery,
} from 'src/products/loans/LoanHistory/LoanHistory.gqls'
import {
  LoanPaymentStatusCode,
  LoanStatusCode,
  LoanSubstatusCode,
} from '@possible/cassandra/src/types/types.mobile.generated'

const visibleLoanStatuses: LoanStatusCode[] = [
  LoanStatusCode.Active,
  LoanStatusCode.Pending,
  LoanStatusCode.Paidoff,
  LoanStatusCode.Chargedoff,
  LoanStatusCode.Approved,
]

const noDocsLoanStatuses: LoanStatusCode[] = [
  LoanStatusCode.Rejected,
  LoanStatusCode.Pending,
  LoanStatusCode.Approved,
  LoanStatusCode.Expired,
  LoanStatusCode.Cancelled,
]

const paymentRemainingDueStatus: LoanPaymentStatusCode[] = [
  LoanPaymentStatusCode.Pending,
  LoanPaymentStatusCode.Failed,
]

type LoanItem = {
  title: string
  date: string
  status: string
}

export type LoanHistoryType = NonNullable<LoanHistoryQuery['me']['loans']['all']>['0']
export type LoanHistoryPayment = (NonNullable<
  LoanHistoryQuery['me']['loans']['all']
>['0']['aggregateStatus'] & {
  __typename: 'ActiveLoanAggregateStatus'
})['activePayments']['payments'][0]

type Props = StackScreenProps<MainStackParamList, 'LoanHistory'>

const LoanHistory: FC<Props> = (props) => {
  const {navigation, route} = props

  const isAdvance = route?.params?.isAdvance
  const {t} = useTranslation('LoanHistory')

  usePageViewedAnalytics({
    eventName: AppEvents.Name.lefthand_drawer_loan_history_screen_viewed,
    eventCategory: AppEvents.Category.Admin,
  })

  const {selectedData} = useCassandraQuery(
    LoanHistoryDocument,
    {
      fetchPolicy: 'cache-first',
    },
    (data: LoanHistoryQuery) => data.me.loans,
  )
  const getProps = (loan: LoanHistoryType): LoanItem => {
    let payments: LoanHistoryPayment[] = []
    switch (loan?.aggregateStatus?.__typename) {
      case 'ActiveLoanAggregateStatus':
        payments = loan.aggregateStatus.activePayments.payments
        break
      case 'ClosedLoanAggregateStatus':
        payments = loan.aggregateStatus.closedPayments.payments
        break
      default:
        break
    }
    const remainingPaymentCount = payments.filter(
      (payment) => paymentRemainingDueStatus.indexOf(payment.statusCode) !== -1,
    ).length

    const data = {
      title: !isAdvance ? t('CurrentLoan') : t('CurrentAdvance'),
      date: t('CurrentLoanDate', {date: formatShortNumericDate(loan?.originationAt)}),
      status: t('CurrentLoanStatus', {count: remainingPaymentCount}),
    }
    const createdAt = formatShortNumericDate(loan.createdAt)
    switch (loan.status.code) {
      case LoanStatusCode.Pending:
        {
          data.title = !isAdvance ? t('LoanSubmittedTitle') : t('AdvanceSubmittedTitle')
          data.date = t('LoanSubmittedDate', {date: createdAt})
          data.status = t('LoanSubmittedStatus')
        }
        break
      case LoanStatusCode.Approved:
        {
          data.title = !isAdvance ? t('LoanApprovedTitle') : t('AdvanceApprovedTitle')
          data.date = t('LoanApproveDate', {date: createdAt})
          data.status = t('LoanApprovedStatus')
        }
        break
      case LoanStatusCode.Chargedoff:
        {
          const isChargedOffCollected =
            'substatusCode' in loan.status &&
            loan.status.substatusCode === LoanSubstatusCode.ChargedoffCollectedFull
          data.title = isChargedOffCollected ? t('PaidOffStatus') : t('ChargedOffStatus')
        }
        break
      case LoanStatusCode.Paidoff:
        data.title = t('PaidOffStatus')
        break
      default:
        break
    }

    return data
  }

  function renderLoan(loan: LoanHistoryType, showDocs: boolean, showStatus: boolean): JSX.Element {
    const {title, date, status} = getProps(loan)
    return (
      <View key={loan.id} style={styles.loan_card_view}>
        <View style={[styles.postextPadding, styles.loan_card_header_view]}>
          <View style={styles.loanState}>
            <PFText variant={'p_lg_semibold'}>{title}</PFText>
          </View>
          <View style={styles.amount_view}>
            <PFText variant={'p_lg_semibold'}>${loan.amountBorrowed}</PFText>
          </View>
        </View>
        <View style={styles.postextPadding}>
          <PFText variant={'p_sm'}>{date}</PFText>
        </View>
        {showStatus ? (
          <View style={styles.postextPadding}>
            <PFText variant={'p_sm'}>{status}</PFText>
          </View>
        ) : null}
        {showDocs ? <Documents navigation={navigation} loan={loan} /> : null}
      </View>
    )
  }

  function renderLoans(): (JSX.Element | null)[] | undefined {
    return selectedData?.all?.map((loan) => {
      let showDocs = true
      let showStatus = false
      if (!loan) return null
      const loanStatus = loan.status.code
      if (!loanStatus) return null
      if (!visibleLoanStatuses.includes(loanStatus)) return null

      if (noDocsLoanStatuses.includes(loanStatus)) showDocs = false

      if (loan.id === selectedData?.latestActionableLoan?.id) {
        showStatus = true
      }
      return renderLoan(loan, showDocs, showStatus)
    })
  }

  return (
    <Page title={!isAdvance ? t('LoanHistoryTitle') : t('AdvanceHistoryTitle')} variant="generic">
      <View style={styles.tileListView}>
        <TileListView>
          <View />
          {renderLoans()}
          <View />
        </TileListView>
      </View>
    </Page>
  )
}

export {LoanHistory}

const styles = StyleSheet.create({
  amount_view: {
    alignSelf: 'flex-end',
  },
  loanState: {
    alignSelf: 'flex-start',
  },
  loan_card_header_view: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  loan_card_view: {
    marginBottom: smallGap,
    paddingTop: smallGap,
  },
  postextPadding: {
    flex: 1,
    marginBottom: smallGap / 2,
  },
  tileListView: {
    flex: 1,
    marginBottom: smallGap / 2,
    width: '100%',
  },
})
