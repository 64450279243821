import {SvgProps} from 'react-native-svg'

import {Color} from 'src/designSystem/types'
import {NamedColors, DefaultVariantsColor} from 'src/designSystem/colors'
import {
  ArrowDown,
  ArrowDownFilled,
  ArrowLeft,
  ArrowLeftFilled,
  ArrowRight,
  ArrowRightFilled,
  ArrowUp,
  ArrowUpFilled,
  Bank,
  BankFilled,
  Calendar,
  CalendarFilled,
  Card,
  CardFilled,
  Chart,
  ChartFilled,
  Chat,
  ChatFilled,
  Check,
  CheckFilled,
  CheckWithCircle,
  CheckWithCircleFilled,
  ChevronDown,
  ChevronDownFilled,
  ChevronLeft,
  ChevronLeftFilled,
  ChevronRight,
  ChevronRightFilled,
  ChevronUp,
  ChevronUpFilled,
  Circle,
  CircleFilled,
  Clock,
  ClockFilled,
  Close,
  CloseFilled,
  Copy,
  CopyFilled,
  Delete,
  DeleteFilled,
  Download,
  DownloadFilled,
  Edit,
  EditFilled,
  Error,
  ErrorFilled,
  ExternalLink,
  ExternalLinkFilled,
  Filter,
  FilterFilled,
  Gift,
  GiftFilled,
  Google,
  GoogleFilled,
  Graph,
  GraphFilled,
  Hide,
  HideFilled,
  Home,
  HomeFilled,
  Info,
  InfoFilled,
  LargeArrowRight,
  LargeArrowRightFilled,
  LargeArrowUpRight,
  LargeArrowUpRightFilled,
  Like,
  LikeFilled,
  Location,
  LocationFilled,
  Lock,
  LockFilled,
  Login,
  LoginFilled,
  Logout,
  LogoutFilled,
  Menu,
  MenuFilled,
  Message,
  MessageFilled,
  Minus,
  MinusFilled,
  Money,
  MoneyFilled,
  MoneyRotating,
  MoneyRotatingFilled,
  MoreHorizontal,
  MoreHorizontalFilled,
  MoreVertical,
  MoreVerticalFilled,
  Notification,
  NotificationFilled,
  Play,
  PlayFilled,
  Plus,
  PlusFilled,
  Profile,
  ProfileFilled,
  Reply,
  ReplyFilled,
  Reveal,
  RevealFilled,
  RotatePhone,
  RotatePhoneFilled,
  Scale,
  ScaleFilled,
  Search,
  SearchFilled,
  Settings,
  SettingsFilled,
  Shield,
  ShieldFilled,
  Sun,
  SunFilled,
  Target,
  TargetFilled,
  Transfer,
  TransferFilled,
  Upload,
  UploadFilled,
  Wallet,
  WalletFilled,
  Warning,
  WarningFilled,
} from 'src/designSystem/components/atoms/SvgIcon/assets'

export type SvgIconSize = (typeof SvgIconSizeValues)[number]

export type SvgIconNames = (typeof SvgIconNamesValues)[number]

export type SvgIconColorVariantNames =
  | 'default'
  | 'active'
  | 'inactive'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | 'white'
  | 'disabled'

const activeIconColor = NamedColors.PRODUCT_BLUE
const defaultIconColor = NamedColors.BLACK
const errorIconColor = DefaultVariantsColor.error
const inactiveIconColor = NamedColors.SILVER
const infoIconColor = DefaultVariantsColor.info
const successIconColor = DefaultVariantsColor.success
const warningIconColor = DefaultVariantsColor.warning
const whiteIconColor = DefaultVariantsColor.white
const disabledIconColor = NamedColors.ASH

type IconColorOptionType = {
  color: Color
}

export const iconColorOptions: {[key in SvgIconColorVariantNames]: IconColorOptionType} = {
  active: {color: activeIconColor},
  default: {color: defaultIconColor},
  error: {color: errorIconColor},
  inactive: {color: inactiveIconColor},
  info: {color: infoIconColor},
  success: {color: successIconColor},
  warning: {color: warningIconColor},
  white: {color: whiteIconColor},
  disabled: {color: disabledIconColor},
}

export const SvgIconSizeValues = ['tiny', 'little', 'small', 'medium', 'large', 'enormous'] as const

export const SvgIconSizeMap: {[key in SvgIconSize]: number} = {
  tiny: 8,
  little: 12,
  small: 16,
  medium: 24,
  large: 32,
  enormous: 40,
}

export const SvgIconNamesValues = [
  'arrowDown',
  'arrowLeft',
  'arrowRight',
  'arrowUp',
  'bank',
  'calendar',
  'card',
  'chart',
  'chat',
  'check',
  'checkWithCircle',
  'chevronDown',
  'chevronLeft',
  'chevronRight',
  'chevronUp',
  'circle',
  'clock',
  'close',
  'copy',
  'delete',
  'download',
  'edit',
  'error',
  'externalLink',
  'filter',
  'gift',
  'google',
  'graph',
  'hide',
  'home',
  'info',
  'largeArrowRight',
  'largeArrowUpRight',
  'like',
  'location',
  'lock',
  'login',
  'logout',
  'menu',
  'message',
  'minus',
  'money',
  'moneyRotating',
  'moreHorizontal',
  'moreVertical',
  'notification',
  'play',
  'plus',
  'profile',
  'reply',
  'reveal',
  'rotatePhone',
  'scale',
  'search',
  'settings',
  'shield',
  'sun',
  'target',
  'transfer',
  'upload',
  'wallet',
  'warning',
] as const

export type SvgIconType = {
  default: React.FC<SvgProps>
  filled: React.FC<SvgProps>
}

export const SvgIconsFull: {[key: string]: SvgIconType} = {
  arrowDown: {
    default: ArrowDown,
    filled: ArrowDownFilled,
  },
  arrowLeft: {
    default: ArrowLeft,
    filled: ArrowLeftFilled,
  },
  arrowRight: {
    default: ArrowRight,
    filled: ArrowRightFilled,
  },
  arrowUp: {
    default: ArrowUp,
    filled: ArrowUpFilled,
  },
  bank: {
    default: Bank,
    filled: BankFilled,
  },
  calendar: {
    default: Calendar,
    filled: CalendarFilled,
  },
  card: {
    default: Card,
    filled: CardFilled,
  },
  chart: {
    default: Chart,
    filled: ChartFilled,
  },
  chat: {
    default: Chat,
    filled: ChatFilled,
  },
  check: {
    default: Check,
    filled: CheckFilled,
  },
  checkWithCircle: {
    default: CheckWithCircle,
    filled: CheckWithCircleFilled,
  },
  chevronDown: {
    default: ChevronDown,
    filled: ChevronDownFilled,
  },
  chevronLeft: {
    default: ChevronLeft,
    filled: ChevronLeftFilled,
  },
  chevronRight: {
    default: ChevronRight,
    filled: ChevronRightFilled,
  },
  chevronUp: {
    default: ChevronUp,
    filled: ChevronUpFilled,
  },
  circle: {
    default: Circle,
    filled: CircleFilled,
  },
  clock: {
    default: Clock,
    filled: ClockFilled,
  },
  close: {
    default: Close,
    filled: CloseFilled,
  },
  copy: {
    default: Copy,
    filled: CopyFilled,
  },
  delete: {
    default: Delete,
    filled: DeleteFilled,
  },
  download: {
    default: Download,
    filled: DownloadFilled,
  },
  edit: {
    default: Edit,
    filled: EditFilled,
  },
  error: {
    default: Error,
    filled: ErrorFilled,
  },
  externalLink: {
    default: ExternalLink,
    filled: ExternalLinkFilled,
  },
  filter: {
    default: Filter,
    filled: FilterFilled,
  },
  gift: {
    default: Gift,
    filled: GiftFilled,
  },
  google: {
    default: Google,
    filled: GoogleFilled,
  },
  graph: {
    default: Graph,
    filled: GraphFilled,
  },
  hide: {
    default: Hide,
    filled: HideFilled,
  },
  home: {
    default: Home,
    filled: HomeFilled,
  },
  info: {
    default: Info,
    filled: InfoFilled,
  },
  largeArrowRight: {
    default: LargeArrowRight,
    filled: LargeArrowRightFilled,
  },
  largeArrowUpRight: {
    default: LargeArrowUpRight,
    filled: LargeArrowUpRightFilled,
  },
  like: {
    default: Like,
    filled: LikeFilled,
  },
  location: {
    default: Location,
    filled: LocationFilled,
  },
  lock: {
    default: Lock,
    filled: LockFilled,
  },
  login: {
    default: Login,
    filled: LoginFilled,
  },
  logout: {
    default: Logout,
    filled: LogoutFilled,
  },
  menu: {
    default: Menu,
    filled: MenuFilled,
  },
  message: {
    default: Message,
    filled: MessageFilled,
  },
  minus: {
    default: Minus,
    filled: MinusFilled,
  },
  money: {
    default: Money,
    filled: MoneyFilled,
  },
  moneyRotating: {
    default: MoneyRotating,
    filled: MoneyRotatingFilled,
  },
  moreHorizontal: {
    default: MoreHorizontal,
    filled: MoreHorizontalFilled,
  },
  moreVertical: {
    default: MoreVertical,
    filled: MoreVerticalFilled,
  },
  notification: {
    default: Notification,
    filled: NotificationFilled,
  },
  play: {
    default: Play,
    filled: PlayFilled,
  },
  plus: {
    default: Plus,
    filled: PlusFilled,
  },
  profile: {
    default: Profile,
    filled: ProfileFilled,
  },
  reply: {
    default: Reply,
    filled: ReplyFilled,
  },
  reveal: {
    default: Reveal,
    filled: RevealFilled,
  },
  rotatePhone: {
    default: RotatePhone,
    filled: RotatePhoneFilled,
  },
  scale: {
    default: Scale,
    filled: ScaleFilled,
  },
  search: {
    default: Search,
    filled: SearchFilled,
  },
  settings: {
    default: Settings,
    filled: SettingsFilled,
  },
  shield: {
    default: Shield,
    filled: ShieldFilled,
  },
  sun: {
    default: Sun,
    filled: SunFilled,
  },
  target: {
    default: Target,
    filled: TargetFilled,
  },
  transfer: {
    default: Transfer,
    filled: TransferFilled,
  },
  upload: {
    default: Upload,
    filled: UploadFilled,
  },
  wallet: {
    default: Wallet,
    filled: WalletFilled,
  },
  warning: {
    default: Warning,
    filled: WarningFilled,
  },
}
