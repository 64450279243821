import React, {ReactElement} from 'react'
import {useTranslation} from 'react-i18next'

import Box from 'src/designSystem/components/atoms/Box/Box'
import {AccountIconButton} from 'src/products/MCU/AccountManagementV2/AddNewAccount/AccountIconButton'
import {SizeVariants} from 'src/designSystem/types'
import {BoxJustifyType} from 'src/designSystem/components/atoms/Box/styles'
import {PaymentMethodTypesEnum} from 'src/products/general/GeneralPaymentMethods/types'

type AddPaymentMethodButtonsProps = {
  onSelectAccountType: (accountType: PaymentMethodTypesEnum) => void
  selectedAccountType: PaymentMethodTypesEnum | null
  useYodleeAggregator?: boolean
  hideAggregatorButton?: boolean
  canAddAdhocPaymentInstrument?: boolean | null
}

const AddPaymentMethodButtons = (props: AddPaymentMethodButtonsProps): ReactElement => {
  const {t} = useTranslation('AccountManagement')

  const {
    onSelectAccountType,
    selectedAccountType,
    useYodleeAggregator: isYodleeAggregator,
    hideAggregatorButton,
    canAddAdhocPaymentInstrument,
  } = props

  const handleOnPlaid = (): void => {
    onSelectAccountType(PaymentMethodTypesEnum.Plaid)
  }

  const handleOnAccountingRouting = (): void => {
    onSelectAccountType(PaymentMethodTypesEnum.AccountRouting)
  }

  const handleOnDebitCard = (): void => {
    onSelectAccountType(PaymentMethodTypesEnum.DebitCard)
  }

  // Styles for three buttons
  let boxProps: {
    justify: BoxJustifyType
    gap?: SizeVariants
  } = {
    justify: 'between',
  }

  // Styles for two buttons
  if (hideAggregatorButton) {
    boxProps = {
      justify: 'center',
      gap: 'large',
    }
  }

  return (
    <Box testID={'AddPaymentMethodButtons'} direction={'row'} {...boxProps}>
      {hideAggregatorButton ? null : (
        <AccountIconButton
          title={isYodleeAggregator ? t('LinkWithYodleeButtonText') : t('LinkWithPlaidButtonText')}
          accountType={
            isYodleeAggregator ? PaymentMethodTypesEnum.Yodlee : PaymentMethodTypesEnum.Plaid
          }
          isActive={selectedAccountType === PaymentMethodTypesEnum.Plaid}
          onPress={handleOnPlaid}
        />
      )}
      <AccountIconButton
        title={t('RoutingAccountingButtonText')}
        accountType={PaymentMethodTypesEnum.AccountRouting}
        isActive={selectedAccountType === PaymentMethodTypesEnum.AccountRouting}
        onPress={handleOnAccountingRouting}
        isDisabled={!canAddAdhocPaymentInstrument}
      />
      <AccountIconButton
        title={t('DebitCardButtonText')}
        accountType={PaymentMethodTypesEnum.DebitCard}
        isActive={selectedAccountType === PaymentMethodTypesEnum.DebitCard}
        onPress={handleOnDebitCard}
        isDisabled={!canAddAdhocPaymentInstrument}
      />
    </Box>
  )
}

export {AddPaymentMethodButtons}
