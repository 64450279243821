import {createInstance, Identify} from '@amplitude/analytics-react-native'
import {LogLevel} from '@amplitude/types'
import {ReactNativeClient} from '@amplitude/analytics-types'

import {DefaultConfiguration} from 'src/lib/Analytics/ampli'

export type AmplitudeClientInstance = ReactNativeClient

export function newAmplitude(key: string): ReactNativeClient {
  const amplitudeInstance = createInstance()
  amplitudeInstance.init(key, undefined, {
    ...DefaultConfiguration,
    sessionTimeout: 1800000, // 30 minutes in milliseconds. See ENG-20706 for details.
    trackingOptions: {country: false},
    logLevel: LogLevel.None,
  })
  return amplitudeInstance
}

export function setIdentityObject(
  property: string,
  value: string,
  _amplitudeInstance: ReactNativeClient,
): void {
  const id = new Identify()
  id.set(property, value)
  _amplitudeInstance.identify(id)
}
