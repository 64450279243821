import React, {FC, useMemo} from 'react'

import DiagArrow from 'src/assets/illustrations/DiagArrow.svg'
import Calendar from 'src/designSystem/components/atoms/PFDescriptor/assets/Calendar.svg'
import InFocus from 'src/designSystem/components/atoms/PFDescriptor/assets/InFocus.svg'
import NoHands from 'src/designSystem/components/atoms/PFDescriptor/assets/NoHands.svg'
import WellLit from 'src/designSystem/components/atoms/PFDescriptor/assets/WellLit.svg'
import Bank from 'src/designSystem/components/atoms/PFDescriptor/assets/bank.svg'
import BankFees from 'src/designSystem/components/atoms/PFDescriptor/assets/bankFees.svg'
import BankFeesFailed from 'src/designSystem/components/atoms/PFDescriptor/assets/bankFeesFailed.svg'
import Charge from 'src/designSystem/components/atoms/PFDescriptor/assets/charge.svg'
import ChargedFees from 'src/designSystem/components/atoms/PFDescriptor/assets/chargedFees.svg'
import ChargedFeesFailed from 'src/designSystem/components/atoms/PFDescriptor/assets/chargedFeesFailed.svg'
import InfoWarning from 'src/designSystem/components/atoms/PFDescriptor/assets/infoWarning.svg'
import Note from 'src/designSystem/components/atoms/PFDescriptor/assets/note.svg'
import Refund from 'src/designSystem/components/atoms/PFDescriptor/assets/refund.svg'
import Security from 'src/designSystem/components/atoms/PFDescriptor/assets/security.svg'
import Shield from 'src/designSystem/components/atoms/PFDescriptor/assets/shield.svg'
import Silhouette from 'src/designSystem/components/atoms/PFDescriptor/assets/silhouette.svg'
import Time from 'src/designSystem/components/atoms/PFDescriptor/assets/time.svg'
import TransactionSuccess from 'src/designSystem/components/atoms/PFDescriptor/assets/transactionSuccess.svg'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'

export type PFDescriptorProps = {
  name: PFDescriptorNames
  size?: PFDescriptorSizes | number
}

export const PFDescriptorNameValues = [
  'bank',
  'bank-fees',
  'bank-fees-failed',
  'charge',
  'charged-fees',
  'charged-fees-failed',
  'refund',
  'security',
  'note',
  'time',
  'info-warning',
  'calendar',
  'in-focus',
  'no-hands',
  'well-lit',
  'transaction-success',
  'shield',
  'silhouette',
  'diag-arrow',
] as const

export type PFDescriptorNames = (typeof PFDescriptorNameValues)[number]

const descriptorIllustrationMap: {[key in PFDescriptorNames]: React.FC<any>} = {
  'bank': Bank,
  'bank-fees': BankFees,
  'bank-fees-failed': BankFeesFailed,
  'charge': Charge,
  'charged-fees': ChargedFees,
  'charged-fees-failed': ChargedFeesFailed,
  'refund': Refund,
  'note': Note,
  'security': Security,
  'time': Time,
  'info-warning': InfoWarning,
  'calendar': Calendar,
  'in-focus': InFocus,
  'no-hands': NoHands,
  'well-lit': WellLit,
  'shield': Shield,
  'silhouette': Silhouette,
  'transaction-success': TransactionSuccess,
  'diag-arrow': DiagArrow,
}

export const PFDescriptorSizeValues = ['small', 'medium', 'large', 'enormous'] as const

export type PFDescriptorSizes = (typeof PFDescriptorSizeValues)[number]

export const descriptorSizesMap: {[key in PFDescriptorSizes]: number} = {
  small: 16,
  medium: 24,
  large: 32,
  enormous: 40,
}

const getDescriptor = (name: PFDescriptorNames, size: number): JSX.Element => {
  const id = `${name}-descriptor`
  const descriptorProps = {
    height: size,
    width: size,
  }

  return <PFSvg id={id} SvgUri={descriptorIllustrationMap[name]} svgProps={descriptorProps} />
}

const PFDescriptor: FC<PFDescriptorProps> = (props) => {
  const {name, size = 'large'} = props
  const sizeValue = descriptorSizesMap[size] ?? size

  // tested this, and it reduces re-renders vs non-memoization
  return useMemo(() => getDescriptor(name, sizeValue), [name, sizeValue])
}

export {PFDescriptor}
