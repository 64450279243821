import React from 'react'
import {Controller, FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {StateDropdown} from 'src/designSystem/components/molecules/StateDropdown/StateDropdown'
import {FieldVariants, FormFieldProps} from 'src/designSystem/components/atoms/HookForm/HookForm'
import Page from 'src/designSystem/components/organisms/Page/Page'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {StateCodes} from 'src/cassandra'
import {StateSelectFormType} from 'src/products/general/StateSelect/StateSelect.types'

export type StateSelectTemplateProps = {
  onSubmit: (data: StateSelectFormType) => void | Promise<void>
  isLoading: boolean
}

export const StateSelectTemplate: React.FC<StateSelectTemplateProps> = (props) => {
  const {onSubmit, isLoading} = props
  const formContext = useForm<StateSelectFormType>({
    defaultValues: {
      state: undefined,
    },
  })
  const stateValue = formContext.watch('state')
  const {t} = useTranslation(['StateSelect', 'Common'])

  const handleOnSubmit = (): void => {
    void formContext.handleSubmit(onSubmit)?.()
  }

  const formPropState: FormFieldProps = {
    name: 'state',
    rules: {
      required: t('Common:LabelIsRequired', {label: t('Common:State')}),
    },
    field: FieldVariants.Dropdown,
  }

  const buttonProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      testID: 'StateSelect-ContinueButton',
      text: t('Common:Continue'),
      disabled: !stateValue || isLoading,
      loading: isLoading,
      onPress: handleOnSubmit,
    },
  }

  return (
    <Page
      testID="StateSelectTemplate"
      variant="generic"
      noHeaderSpacer
      buttonProps={buttonProps}
      title={t('StateSelectTitle')}
    >
      <FormProvider {...formContext}>
        <PFText variant="p">{t('StateSelectSubtitle')}</PFText>
        <Box paddingTop={'small'}>
          <Controller
            control={formContext.control}
            name="state"
            render={() => (
              <StateDropdown
                label={t('Common:State')}
                formProps={formPropState}
                useValueForLabel={true}
                testID="State-Select-Dropdown"
                value={stateValue}
                onSelection={(value: StateCodes): void => formContext.setValue('state', value)}
              />
            )}
          />
        </Box>
      </FormProvider>
    </Page>
  )
}
