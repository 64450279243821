import React, {ReactNode} from 'react'
import {HeaderBackButtonProps} from '@react-navigation/elements'
import {ParamListBase, RouteProp} from '@react-navigation/native'
import {StackNavigationProp} from '@react-navigation/stack'

import {Color} from 'src/designSystem/types'
import {HeaderBackButton} from 'src/nav/Header/HeaderBackButton/HeaderBackButton'
import {HeaderLogoutButton} from 'src/nav/Header/HeaderLogoutButton/HeaderLogoutButton'
import {HeaderMenuButton} from 'src/nav/Header/HeaderMenuButton/HeaderMenuButton'
import {WorkflowHeaderButton} from 'src/workflows/types'
import Box from 'src/designSystem/components/atoms/Box/Box'

export const HeaderButtonOptionFactory = ({
  headerButton,
  navigation,
  route,
}: {
  headerButton: WorkflowHeaderButton | undefined
  navigation: StackNavigationProp<ParamListBase>
  route: RouteProp<ParamListBase>
}): ((props: HeaderBackButtonProps) => React.ReactNode) | undefined => {
  if (!headerButton) {
    return undefined
  }

  const components: Record<WorkflowHeaderButton, (props: HeaderBackButtonProps) => ReactNode> = {
    Logout: function LogoutButton(props: HeaderBackButtonProps) {
      const {tintColor} = props
      return (
        <Box paddingHorizontal="medium" paddingVertical="tiny">
          {/* We need to disable this for tintColor because it comes from the HeaderProps as a string */}
          {/* eslint-disable-next-line  no-type-assertion/no-type-assertion */}
          <HeaderLogoutButton color={tintColor as Color} />
        </Box>
      )
    },

    Menu: function MenuButton() {
      return (
        <Box paddingHorizontal="medium" paddingVertical="small">
          <HeaderMenuButton />
        </Box>
      )
    },

    Back: function BackButton(props: HeaderBackButtonProps) {
      const {tintColor} = props

      return (
        <Box paddingHorizontal="medium" paddingVertical="small">
          <HeaderBackButton
            testID={`HeaderBackButton-${route.name}`}
            navigation={navigation}
            // We need to disable this for tintColor because it comes from the HeaderProps as a string
            //  eslint-disable-next-line  no-type-assertion/no-type-assertion
            color={tintColor as Color}
          />
        </Box>
      )
    },

    None: function NoneButton() {
      return null
    },
  }

  return components[headerButton]
}
