import React, {FC, useCallback, useMemo, ReactNode} from 'react'
import {Control, FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {TFunction} from 'i18next'

import HookForm, {
  FieldVariants,
  FormFieldProps,
} from 'src/designSystem/components/atoms/HookForm/HookForm'
import {
  accountNumberMatchesMask,
  getHasErrorsOrMissingValues,
  isRoutingNumberDigitValid,
  isRoutingNumberLengthValid,
  isValidAccountNumber,
  routingNumberLength,
} from 'src/lib/utils/formValidationUtil'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {routingNumberFilter} from 'src/lib/bank_account_utils'
import {isStringValueEmpty} from 'src/designSystem/lib/dataUtil'
import {Alert} from 'src/designSystem/components/molecules/Alert/Alert'
import {PFInfoCapsule} from 'src/designSystem/components/molecules/PFInfoCapsule/PFInfoCapsule'

const getDuplicatedNumbersAlert = (
  enteredDuplicatedNumbers: boolean | undefined,
  t: TFunction,
): ReactNode | undefined => {
  if (!enteredDuplicatedNumbers) {
    return undefined
  }
  return (
    <Box marginBottom={'little'} paddingHorizontal={'tiny'}>
      <Alert description={t('MultipleAccountsHaveTheSameNumber')} level={'error'} />
    </Box>
  )
}

const getContactUsCapsule = (t: TFunction): ReactNode | undefined => {
  return (
    <Box marginTop={'small'} paddingHorizontal={'tiny'}>
      <PFInfoCapsule
        svgIcon={{name: 'warning', colorVariant: 'warning', isFilled: true}}
        text={t('ContactUsAboutMask')}
      />
    </Box>
  )
}

export const checkErrors = (
  showRoutingNumberField: boolean,
  requiredAccountNumbers: AccountInputPropTypes[],
  errors: FieldErrors,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch: (names?: string | string[]) => any,
): boolean => {
  const names = [
    ...(showRoutingNumberField ? [routingNumberFieldName] : []),
    ...requiredAccountNumbers.map((account) => `${accountNumberPrefix}${account.id}`),
  ]

  return getHasErrorsOrMissingValues(errors, watch, names)
}

export const getDefaultFormValues = (
  showRoutingNumberField: boolean,
  routingNumberFieldId: string,
  defaultRoutingNumber: string,
): object => {
  return showRoutingNumberField
    ? {
        [routingNumberFieldId]: defaultRoutingNumber,
      }
    : {}
}

const getRoutingNumberValidationRule = (value: string, t: TFunction): string | undefined => {
  if (!isRoutingNumberLengthValid(value)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- error due to use of translation
    return t('LabelMustBeXDigits', {label: t('RoutingNumber'), routingNumberLength})
  } else if (!isRoutingNumberDigitValid(value)) {
    return t('WrongRoutingNumberDigit')
  } else {
    return undefined
  }
}

type IsRequiredTextType = 'routingNumber' | 'accountNumber'

const getIsRequiredLabel = (accountInfoType: IsRequiredTextType, t: TFunction): string => {
  let label: string
  if (accountInfoType === 'routingNumber') {
    label = t('RoutingNumber')
  } else if (accountInfoType === 'accountNumber') {
    label = t('Common:AccountNumber')
  } else {
    return ''
  }
  return t('Common:LabelIsRequired', {label: label})
}

const getAccountNumbersValidationRule = (
  value: string,
  mask: string,
  t: TFunction,
): string | undefined => {
  if (!isValidAccountNumber(value)) {
    return getIsRequiredLabel('accountNumber', t)
  } else if (!isStringValueEmpty(mask) && !accountNumberMatchesMask(value, mask)) {
    return t('AccountNumberShouldMatch')
  } else {
    return undefined
  }
}

export const getValidRoutingNumber = (
  formRoutingNumber: string,
  defaultRoutingNumber: string,
): string => {
  return !isStringValueEmpty(formRoutingNumber) ? formRoutingNumber : defaultRoutingNumber
}

export const getErrorMessage = (e): string => {
  return e?.message ?? e
}

export type AccountInputPropTypes = {
  id?: string
  mask?: string
  name?: string
}

export type BankNumbersFormData = Record<string, string>

type Props = {
  control: Control<BankNumbersFormData>
  errors: FieldErrors
  requiredAccountNumbers: AccountInputPropTypes[]
  showRoutingNumberField: boolean
  defaultRoutingNumber?: string
  institutionName?: string
  duplicatedNumbers?: boolean
}

export const routingNumberFieldName: string = 'routingNumber'
export const accountNumberPrefix: string = 'accountNumber-'

const BankNumbersForm: FC<Props> = (props) => {
  const {
    control,
    errors,
    showRoutingNumberField,
    defaultRoutingNumber,
    requiredAccountNumbers,
    institutionName,
    duplicatedNumbers,
  } = props
  const {t} = useTranslation(['BankNumbersForm', 'Common'])

  const formProps = useMemo(
    (): {[k: string]: FormFieldProps} =>
      Object.assign(
        showRoutingNumberField
          ? {
              [routingNumberFieldName]: {
                name: routingNumberFieldName,
                field: FieldVariants.TextField,
                defaultValue: defaultRoutingNumber ?? '',
                rules: {
                  required: getIsRequiredLabel('routingNumber', t),
                  validate: (value) => getRoutingNumberValidationRule(value, t),
                },
              },
            }
          : {},
        ...requiredAccountNumbers.map((account) => {
          const key = `${accountNumberPrefix}${account.id}`
          return {
            [key]: {
              name: key,
              field: FieldVariants.TextField,
              rules: {
                validate: (value) => getAccountNumbersValidationRule(value, account.mask ?? '', t),
              },
            },
          }
        }),
      ),
    [defaultRoutingNumber, t, requiredAccountNumbers, showRoutingNumberField],
  )

  const renderAccountFields = useCallback(() => {
    return requiredAccountNumbers.map((account) => {
      const key = `${accountNumberPrefix}${account.id}`
      return (
        <PFTextInput
          key={account.id}
          label={t('AccountNumberWithMask', {mask: account.mask})}
          formProps={formProps[key]}
          returnKeyType="done"
          keyboardType="numeric"
          testID="CardVerifyBankDetails_accountNumber"
        />
      )
    })
  }, [requiredAccountNumbers, formProps, t])

  return (
    <>
      {getDuplicatedNumbersAlert(duplicatedNumbers, t)}
      <HookForm control={control} errors={errors}>
        {showRoutingNumberField ? (
          <PFTextInput
            label={
              institutionName ? t('BankRoutingNumber', {bank: institutionName}) : t('RoutingNumber')
            }
            changeFilter={routingNumberFilter}
            formProps={formProps.routingNumber}
            returnKeyType="done"
            keyboardType="numeric"
            errorOnEmpty={true}
            testID="CardVerifyBankDetails_routingNumber"
          />
        ) : undefined}
        {renderAccountFields()}
      </HookForm>
      {getContactUsCapsule(t)}
    </>
  )
}
export default BankNumbersForm
