import React, {FC, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {StyleSheet, View} from 'react-native'

import Card from 'src/assets/icons/Card.svg'
import LargeArrow from 'src/assets/icons/LargeArrow.svg'
import {CardStaticAgreementType} from 'src/cassandra'
import {NamedColors} from 'src/designSystem/colors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import IconFeatureList from 'src/designSystem/components/molecules/IconFeatureList/IconFeatureList'
import {SchumerBox} from 'src/designSystem/components/molecules/SchumerBox/SchumerBox'
import Page from 'src/designSystem/components/organisms/Page/Page'
import {smallGap} from 'src/designSystem/layout'
import {CardAcceptStaticAgreement} from 'src/products/card/Activation/CardAcceptDocument/CardAcceptStaticAgreement'
import {PFUnorderedList} from 'src/designSystem/components/molecules/PFUnorderedList/PFUnorderedList'

type CardAgreementViewerTemplateProps = {
  onPress: () => Promise<void>
  isSubmitting: boolean
  called: boolean
}

const CardAgreementBureauTemplate: FC<CardAgreementViewerTemplateProps> = ({
  onPress,
  isSubmitting,
  called,
}) => {
  const {t} = useTranslation(['CardAgreementBureau', 'CardPaymentAgreement', 'PersonalInformation'])
  const [isDocChecked, setIsDocChecked] = useState(false)

  return (
    <Page
      buttonProps={{
        type: 'singleButton',
        primary: {
          text: t('SubmitApplication'),
          onPress,
          loading: isSubmitting,
          disabled: !isDocChecked || called,
        },
      }}
      variant="generic"
      title={t('YourLastStep')}
      smallTopGap
    >
      <PFText variant="p">{t('ReviewDisclosuresAuthorizationsAgreement')}</PFText>
      <PFText variant="p_semibold" textProps={{style: styles.paragraph}}>
        1. {t('CardDisclosures')}
      </PFText>
      <Box marginTop="small">
        <SchumerBox
          rows={[
            {
              label: t('APRForPurchases'),
              content: '0%',
              key: '1',
            },
            {
              label: t('MonthlyFeesByLimit'),
              content: `${t('CreditLimit400')}\n\n${t('CreditLimit800')}`,
              key: '2',
            },
            {
              label: t('TransactionFees'),
              key: '3',
            },
            {
              subLabel: t('ForeignTransaction'),
              content: t('None'),
              key: '4',
            },
            {
              label: t('PenaltyFees'),
              key: '5',
            },
            {
              subLabel: t('LatePayment'),
              content: t('None'),
              key: '6',
            },
            {
              subLabel: t('OverTheCreditLimit'),
              content: t('None'),
              key: '7',
            },
            {
              subLabel: t('ReturnedPayment'),
              content: t('None'),
              key: '8',
            },
          ]}
        />
      </Box>
      <Box marginTop="large">
        <PFText variant="p_semibold" textProps={{style: styles.paragraph}}>
          2. {t('Authorizations')}
        </PFText>
        <PFText variant="p" textProps={{style: styles.paragraph}}>
          {t('AuthorizationsBody1')}
        </PFText>
        <PFUnorderedList
          gap="small"
          items={[
            {value: t('AuthorizationsBullet1'), key: 'AuthorizationsBullet1'},
            {value: t('AuthorizationsBullet2'), key: 'AuthorizationsBullet2'},
            {value: t('AuthorizationsBullet3'), key: 'AuthorizationsBullet3'},
          ]}
        />
        <PFText variant="p" textProps={{style: styles.paragraph}}>
          {t('AuthorizationsBody2')}
        </PFText>
      </Box>

      <Box boxStyle={styles.containerStyle} marginTop="large">
        <PFText variant="p_semibold">3. {t('CardBetaTest')}</PFText>

        <Box marginBottom="large" marginTop="large">
          <IconFeatureList
            items={[
              {
                text: t('BetaLaunch'),
                icon: LargeArrow,
                iconWidth: 24,
              },
              {
                text: (
                  <Trans
                    t={t}
                    i18nKey="BetaLaunch2"
                    components={{bold: <PFText variant="p_semibold" />}}
                  />
                ),
                icon: Card,
                iconWidth: 24,
              },
            ]}
          />
        </Box>

        <View style={styles.checkbox}>
          <CardAcceptStaticAgreement
            checked={isDocChecked}
            setChecked={setIsDocChecked}
            cardStaticAgreementType={CardStaticAgreementType.BetaTestingAgreement}
            agreementName={t('CardPaymentAgreement:BetaTestAgreement')}
          />
        </View>
      </Box>

      <Box marginBottom="medium" gap={'small'} marginTop={'small'} align={'center'}>
        <PFText color={NamedColors.SILVER} variant={'p_sm'}>
          {t('AgreeTermsOfUse')}
        </PFText>

        <PFText variant="p_sm" color={NamedColors.SILVER}>
          {t('PersonalInformation:YourLocationDisclaimer')}
        </PFText>
      </Box>
    </Page>
  )
}

const styles = StyleSheet.create({
  containerStyle: {flex: 1},
  paragraph: {marginTop: smallGap},
  checkbox: {
    backgroundColor: NamedColors.WHITESMOKE,
    borderWidth: 1,
    borderColor: NamedColors.ASH,
    paddingLeft: smallGap,
    paddingRight: smallGap,
    paddingTop: smallGap,
    paddingBottom: smallGap,
  },
})
export {CardAgreementBureauTemplate}
