import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'

import {Alert, AlertProps} from 'src/designSystem/components/molecules/Alert/Alert'
import {
  LoanAlertTile,
  LoanAlertTileProps,
} from 'src/products/loans/Dashboard/LoanAlertTile/LoanAlertTile'

export const AdvanceAlertTile: FC<LoanAlertTileProps> = (props) => {
  const {alert, testID} = props
  const {t} = useTranslation(['DashboardLoanAlertTile', 'Common'])

  let alertProps:
    | Pick<AlertProps, 'level' | 'title' | 'description' | 'onPress' | 'testID'>
    | undefined = undefined

  if (alert.__typename === 'LoanActiveAlertDisbursementCompleted') {
    alertProps = {
      level: 'success',
      title: t('DisbursementCompletedTitleAdvance'),
    }
  }

  if (alertProps) {
    const finalAlertProps: AlertProps = {
      level: alertProps.level,
      title: alertProps.title,
      description: alertProps.description,
      onPress: alertProps.onPress ?? undefined,
      testID,
    }
    return <Alert {...finalAlertProps} />
  } else {
    return <LoanAlertTile {...props} />
  }
}
