import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import PFTextInput, {PFTextInputProps} from 'src/designSystem/components/atoms/PFTextInput'
import {SessionReplay} from 'src/lib/sessionReplay/sessionReplay'
import {ssnLength} from 'src/lib/utils/formValidationUtil'

type PFSSNInputProps = Omit<PFTextInputProps, 'label'> & {
  value: string
  label?: string
}

const dashesLength = 2

const PFSSNInput = (props: PFSSNInputProps): JSX.Element => {
  const {t} = useTranslation(['Common'])
  const {onChangeText, label = t('Common:SocialSecurityNumber'), ...otherProps} = props

  const handleOnFormatSsn = useCallback(
    (text: string): void => {
      const formattedSsn = text.replace(/\D/g, '').replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
      onChangeText?.(formattedSsn)
    },
    [onChangeText],
  )

  return (
    <PFTextInput
      {...otherProps}
      testID="SSN-Input"
      maxLength={ssnLength + dashesLength}
      onChangeText={handleOnFormatSsn}
      keyboardType="number-pad"
      label={label}
      {...SessionReplay.privacyProps()}
    />
  )
}

export {PFSSNInput}
