/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, {useCallback, useMemo, useState} from 'react'
import {ScrollView, StyleSheet, Text, TextInput, View} from 'react-native'
import {TouchableOpacity} from 'react-native-gesture-handler'

import Box from 'src/designSystem/components/atoms/Box/Box'
import Button from 'src/designSystem/components/atoms/Button/Button'
import PFCheckbox from 'src/designSystem/components/atoms/PFCheckbox/PFCheckbox'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import Overlay from 'src/designSystem/components/organisms/Overlay/Overlay'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import {NamedColors} from 'src/designSystem/colors'
import {PfReduxState} from 'src/reducers/types'
import {
  getChaosFailedMutations,
  getChaosFailedQueries,
  getChaosRegisteredComponents,
  graphqlChaosActions,
  componentChaosActions,
  getChaosFailedComponentRenders,
  getChaosResponseMockMutations,
} from '@possible/chaos'
import {
  AllMutationOperationNames,
  AllQueryOperationNames,
} from 'src/products/general/VersionFooter/ChaosModeSettings/ChaosModeOperationsConfig'
import {MutationChaosToggle} from 'src/products/general/VersionFooter/ChaosModeSettings/MutationChaosToggle'
import {OpenWebPage} from 'src/navigation/NavHelper'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'

interface ChaosOverlayContentProps {
  isHelpVisible: boolean
}

type ChaosOverlayContentSections = 'queries' | 'mutations' | 'components'
/**
 * Modal content of the Chaos Mode settings
 */
const ChaosOverlayContent: React.FC<ChaosOverlayContentProps> = (props) => {
  const dispatch = usePfDispatch()
  const {isHelpVisible} = props
  const [selectedSection, setSelectedSection] = useState<ChaosOverlayContentSections | null>(
    'queries',
  )
  // queries
  const [queriesSearchTerm, setQueriesSearchTerm] = useState<string>('')
  const chaosFailureQueries = usePfSelector((state: PfReduxState) => {
    return getChaosFailedQueries(state.chaos)
  })
  const handleQueryToggle = useCallback(
    (queryName: string) => (checked: boolean) => {
      if (checked) {
        dispatch(graphqlChaosActions.enableChaosFailureForQuery({queryName}))
      } else {
        dispatch(graphqlChaosActions.disableChaosFailureForQuery({queryName}))
      }
    },
    [dispatch],
  )

  // mutations
  const [mutationsSearchTerm, setMutationsSearchTerm] = useState<string>('')
  const chaosFailureMutations = usePfSelector((state: PfReduxState) => {
    return getChaosFailedMutations(state.chaos)
  })
  const chaosResponseMockMutations = usePfSelector((state: PfReduxState) => {
    return getChaosResponseMockMutations(state.chaos)
  })

  // components
  const [componentsSearchTerm, setComponentsSearchTerm] = useState<string>('')
  const chaosRegisteredComponents = usePfSelector((state: PfReduxState) => {
    return getChaosRegisteredComponents(state.chaos)
  })
  const chaosFailureComponentRenders = usePfSelector((state: PfReduxState) => {
    return getChaosFailedComponentRenders(state.chaos)
  })
  const chaosRegisteredComponentNames = Object.keys({
    ...chaosRegisteredComponents,
    ...chaosFailureComponentRenders,
  })
  const handleComponentToggle = useCallback(
    (componentName: string) => (checked: boolean) => {
      if (checked) {
        dispatch(componentChaosActions.enableChaosFailureForComponentRender({componentName}))
      } else {
        dispatch(componentChaosActions.disableChaosFailureForComponentRender({componentName}))
      }
    },
    [dispatch],
  )

  const queriesSelection = useMemo(
    () => (
      <>
        <TextInput
          placeholder={'Search queries'}
          onChangeText={(value: string): void => {
            setQueriesSearchTerm(value)
          }}
          style={styles.searchBox}
          value={queriesSearchTerm}
        />
        <View>
          {AllQueryOperationNames.filter((queryName) => {
            if (!queriesSearchTerm) {
              return true
            } else {
              return queryName.toLowerCase().indexOf(queriesSearchTerm.toLowerCase()) !== -1
            }
          })
            .sort((queryNameA, queryNameB) => {
              // shoq queries w/chaos enabled first
              if (chaosFailureQueries[queryNameA] && !chaosFailureQueries[queryNameB]) {
                return -1
              }
              return 1
            })
            .map((queryName) => (
              <PFCheckbox
                key={queryName}
                checked={chaosFailureQueries[queryName] === true}
                onPress={handleQueryToggle(queryName)}
                size={'Small'}
              >
                {queryName}
              </PFCheckbox>
            ))}
        </View>
      </>
    ),
    [chaosFailureQueries, queriesSearchTerm, handleQueryToggle],
  )

  const mutationsSelection = useMemo(() => {
    return (
      <>
        <TextInput
          placeholder={'Search mutations'}
          onChangeText={(value: string): void => {
            setMutationsSearchTerm(value)
          }}
          style={styles.searchBox}
          value={mutationsSearchTerm}
        />
        <View>
          {AllMutationOperationNames.filter((mutationName) => {
            if (!mutationsSearchTerm) {
              return true
            } else {
              return mutationName.toLowerCase().indexOf(mutationsSearchTerm.toLowerCase()) !== -1
            }
          })
            .sort((mutationNameA, mutationNameB) => {
              // show mutations w/chaos enabled first
              if (chaosFailureMutations[mutationNameA] && !chaosFailureMutations[mutationNameB]) {
                return -1
              }
              if (
                chaosResponseMockMutations[mutationNameA] &&
                !chaosResponseMockMutations[mutationNameB]
              ) {
                return -1
              }

              return 1
            })
            .map((mutationName) => (
              <MutationChaosToggle key={mutationName} mutationName={mutationName} />
            ))}
        </View>
      </>
    )
  }, [chaosFailureMutations, chaosResponseMockMutations, mutationsSearchTerm])
  const componentsSelection = useMemo(
    () => (
      <>
        <TextInput
          placeholder={'Search components'}
          onChangeText={(value: string): void => {
            setComponentsSearchTerm(value)
          }}
          style={styles.searchBox}
          value={componentsSearchTerm}
        />
        <View>
          {chaosRegisteredComponentNames
            .filter((componentName) => {
              if (!componentsSearchTerm) {
                return true
              } else {
                return (
                  componentName.toLowerCase().indexOf(componentsSearchTerm.toLowerCase()) !== -1
                )
              }
            })
            .sort((componentNameA, componentNameB) => {
              if (
                chaosFailureComponentRenders[componentNameA] &&
                !chaosFailureComponentRenders[componentNameB]
              ) {
                return -1
              }
              return 1
            })
            .map((componentName) => (
              <PFCheckbox
                key={componentName}
                checked={chaosFailureComponentRenders[componentName] === true}
                onPress={handleComponentToggle(componentName)}
                size={'Small'}
              >
                {componentName}
              </PFCheckbox>
            ))}
        </View>
      </>
    ),
    [
      chaosFailureComponentRenders,
      chaosRegisteredComponentNames,
      componentsSearchTerm,
      handleComponentToggle,
    ],
  )
  const handleOnTapHelpLink = (): void => {
    OpenWebPage({
      uri: 'https://www.notion.so/possiblefinance/Chaos-Mode-How-to-simulate-failures-604cb9f2f4f646468e1746550959e920',
    })
  }
  const handleTapSectionHeading = (section: ChaosOverlayContentSections): void => {
    if (selectedSection === section) {
      setSelectedSection(null)
    } else {
      setSelectedSection(section)
    }
  }

  return (
    <Box height={550} gap="small">
      <Box margin={0} padding={0}>
        {isHelpVisible ? (
          <PFText variant="p_sm">
            For help using Chaos Mode you can find a guide on notion titled: &nbsp;
            <SvgLink
              linkType={'inline'}
              onPress={handleOnTapHelpLink}
              linkText={`"Chaos Mode - How to simulate failures"`}
              textVariant={'p_sm'}
            />
          </PFText>
        ) : null}
      </Box>
      <Box boxStyle={styles.chaosSectionContainer}>
        <TouchableOpacity
          onPress={(): void => {
            handleTapSectionHeading('queries')
          }}
        >
          <PFText variant="label_md" textAlign="center">
            Simulate query chaos &nbsp;
            <Box>
              <SvgIcon
                name={selectedSection === 'queries' ? 'chevronUp' : 'chevronDown'}
                colorVariant={selectedSection === 'queries' ? 'error' : 'active'}
                size="small"
              />
            </Box>
          </PFText>
        </TouchableOpacity>
        {selectedSection === 'queries' ? <ScrollView>{queriesSelection}</ScrollView> : null}
      </Box>
      <Box boxStyle={styles.chaosSectionContainer}>
        <TouchableOpacity
          onPress={(): void => {
            handleTapSectionHeading('mutations')
          }}
        >
          <PFText variant="label_md" textAlign="center">
            Simulate mutation chaos &nbsp;
            <Box>
              <SvgIcon
                name={selectedSection === 'mutations' ? 'chevronUp' : 'chevronDown'}
                colorVariant={selectedSection === 'mutations' ? 'error' : 'active'}
                size="small"
              />
            </Box>
          </PFText>
        </TouchableOpacity>
        {selectedSection === 'mutations' ? <ScrollView>{mutationsSelection}</ScrollView> : null}
      </Box>

      <Box boxStyle={styles.chaosSectionContainer}>
        <TouchableOpacity
          onPress={(): void => {
            handleTapSectionHeading('components')
          }}
        >
          <PFText variant="label_md" textAlign="center">
            Simulate failed components &nbsp;
            <Box>
              <SvgIcon
                name={selectedSection === 'components' ? 'chevronUp' : 'chevronDown'}
                colorVariant={selectedSection === 'components' ? 'error' : 'active'}
                size="small"
              />
            </Box>
          </PFText>
          <Text style={styles.componentFailureNote}>
            (You must view a component once before it appears in this list. After failing this will
            disable itself again. )
          </Text>
        </TouchableOpacity>

        {selectedSection === 'components' ? <ScrollView>{componentsSelection}</ScrollView> : null}
      </Box>
    </Box>
  )
}

/**
 * An interface for changing Chaos Mode settings. This works with the @possible/chaos package
 * to select which Apollo queries/mutations to simulate failures for, as well as which components
 * should simulate render failure.
 */
const ChaosModeSettings: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const dispatch = usePfDispatch()
  const [isHelpVisible, setIsHelpVisible] = useState(false)
  const handleOnPressHelp = (): void => {
    setIsHelpVisible(!isHelpVisible)
  }

  // enable usage through browser console Chaos object
  /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
  // @ts-expect-error Chaos object doesn't usually exist on global window
  window.Chaos = window.Chaos || {}
  // @ts-expect-error Chaos object doesn't usually exist on global window
  window.Chaos.showChaosModeSettings = (): void => {
    setIsVisible(true)
  }
  // @ts-expect-error Chaos object doesn't usually exist on global window
  window.Chaos.hideChaosModeSettings = (): void => {
    setIsVisible(false)
  }

  // @ts-expect-error Chaos object doesn't usually exist on global window
  window.Chaos = window.Chaos || {}
  // @ts-expect-error Chaos object doesn't usually exist on global window
  window.Chaos.component = window.Chaos.component || {}
  // @ts-expect-error Chaos object doesn't usually exist on global window
  window.Chaos.component.enableChaosFailureForComponentRender = (
    params: Parameters<typeof componentChaosActions.enableChaosFailureForComponentRender>['0'],
  ): void => {
    dispatch(componentChaosActions.enableChaosFailureForComponentRender(params))
  }
  // @ts-expect-error Chaos object doesn't usually exist on global window
  window.Chaos.component.disableChaosFailureForComponentRender = (
    params: Parameters<typeof componentChaosActions.disableChaosFailureForComponentRender>['0'],
  ): void => {
    dispatch(componentChaosActions.disableChaosFailureForComponentRender(params))
  }
  // @ts-expect-error Chaos object doesn't usually exist on global window
  window.Chaos.graphql = window.Chaos.graphql || {}
  // @ts-expect-error Chaos object doesn't usually exist on global window
  window.Chaos.graphql.enableChaosFailureForQuery = (
    params: Parameters<typeof graphqlChaosActions.enableChaosFailureForQuery>['0'],
  ): void => {
    dispatch(graphqlChaosActions.enableChaosFailureForQuery(params))
  }
  // @ts-expect-error Chaos object doesn't usually exist on global window
  window.Chaos.graphql.disableChaosFailureForQuery = (
    params: Parameters<typeof graphqlChaosActions.disableChaosFailureForQuery>['0'],
  ): void => {
    dispatch(graphqlChaosActions.disableChaosFailureForQuery(params))
  }
  // @ts-expect-error Chaos object doesn't usually exist on global window
  window.Chaos.graphql.enableChaosFailureForMutation = (
    params: Parameters<typeof graphqlChaosActions.enableChaosFailureForMutation>['0'],
  ): void => {
    dispatch(graphqlChaosActions.enableChaosFailureForMutation(params))
  }
  // @ts-expect-error Chaos object doesn't usually exist on global window
  window.Chaos.graphql.disableChaosFailureForMutation = (
    params: Parameters<typeof graphqlChaosActions.disableChaosFailureForMutation>['0'],
  ): void => {
    dispatch(graphqlChaosActions.disableChaosFailureForMutation(params))
  }
  /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */

  return (
    <>
      <Box margin={'tiny'} align="center">
        <Button
          mode="primary"
          onPress={(): void => setIsVisible((prev) => !prev)}
          size="medium"
          width={200}
        >
          Chaos Mode Settings
        </Button>
      </Box>

      <Overlay
        visible={isVisible}
        title={
          <Box justify="center" direction="row" width="100%">
            <PFText variant="h2">Chaos Mode </PFText>
            <View style={styles.helpIcon}>
              <SvgLink
                linkType={'inline'}
                onPress={handleOnPressHelp}
                linkText={isHelpVisible ? 'Close help' : 'Need help?'}
              />
            </View>
          </Box>
        }
        titleAlign="center"
        dismissButtonText="Done"
        onOkay={(): void => setIsVisible((prev) => !prev)}
        onDismiss={(): void => setIsVisible((prev) => !prev)}
        testID="ChaosModeSettingsModal"
      >
        <ChaosOverlayContent isHelpVisible={isHelpVisible} />
      </Overlay>
    </>
  )
}

export {ChaosModeSettings}

const styles = StyleSheet.create({
  searchBox: {
    borderColor: NamedColors.BLACK,
    borderRadius: 5,
    borderWidth: 1,
    padding: 10,
  },
  chaosSectionContainer: {
    maxHeight: '80%',
  },
  componentFailureNote: {
    fontSize: 10,
    textAlign: 'left',
  },
  helpIcon: {
    position: 'absolute',
    right: 0,
    top: -20,
  },
})
