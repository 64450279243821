import {StackNavigationProp} from '@react-navigation/stack'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {completeUra} from 'src/cassandra'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {ShowException} from 'src/lib/errors'
import {get_ura_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {PushPage} from 'src/navigation/NavHelper'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'
import {URAProps} from 'src/products/general/UserRequestedActions/URAProps'
import {
  launchAggregator,
  launchAlternateAggregator,
} from 'src/products/MCU/AccountManagementV2/PaymentMethods/BankAggregator/BankAggregatorHelper'
import {usePfSelector} from 'src/store/utils'

type Props = URAProps & {
  navigation: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
}

const BankAddressMismatch: React.FunctionComponent<Props> = (props: Props) => {
  const {ura_id, navigation} = props
  const [busy, setBusy] = useState(false)
  const {t} = useTranslation('UserRequestedAction')

  const ura = usePfSelector((state) => get_ura_by_id_from_redux(ura_id, state))

  const onBankComplete = async (success): Promise<void> => {
    try {
      if (success) {
        await completeUra(props.ura_id)
        props.close()
      }
    } catch (e) {
      ShowException(e)
    }
  }

  const bankAggregatorSwitchCallback = (
    alternateId: string,
    accountId: string | undefined,
  ): void => {
    launchAlternateAggregator(alternateId, accountId, navigation, (success) =>
      onBankComplete(success),
    )
  }

  const onLinkAccount = async (): Promise<void> => {
    setBusy(true)
    await launchAggregator(
      props.navigation,
      null,
      (success) => onBankComplete(success),
      (alternateId, accountId) => bankAggregatorSwitchCallback(alternateId, accountId),
    )
    setBusy(false)
  }

  const onComplete = async (): Promise<void> => {
    try {
      TrackAppEvent(
        AppEvents.Name.provide_proof_of_residency_completed,
        AppEvents.Category.Application,
      )
      setBusy(true)
      await completeUra(props.ura_id)
      props.close()
    } catch (e) {
      ShowException(e)
    }
  }

  const onRetake = (): void => {
    PushPage(props.navigation, 'ProofOfResidence', {
      noAddressFill: true,
      uraActionType: ura?.actionType,
      onProofComplete: () => onComplete(),
    })
  }

  const primary = {
    text: t('VerifyTheAddress'),
    disabled: busy,
    onPress: onRetake,
  }

  const secondary = {
    text: t('LinkANewAccount'),
    disabled: busy,
    onPress: onLinkAccount,
  }

  return (
    <URAModalContent
      uraId={props.ura_id}
      ura={ura}
      primary={primary}
      secondary={secondary}
      close={props.close}
    />
  )
}

export default BankAddressMismatch
