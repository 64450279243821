import {Consumer, GetMeAction, CardPaymentMethodType, GetMe} from 'src/cassandra'
import {
  findPaymentAccountType,
  PaymentTypes,
} from 'src/products/card/PaymentMethods/PaymentMethodUtils'
import {setSelectedPayNowPaymentMethod} from 'src/lib/card/slice'
import {usePfDispatch} from 'src/store/utils'

/**
 * See https://www.notion.so/possiblefinance/Cards-Front-End-Payments-31dbb8bf8b454acc8ad0ec66bffec5c4
 * for details on what is happening here
 */
type UseLinkPaymentMethodType = (
  paymentAccountId?: string,
  shouldSetSelectedPayNowPaymentMethod?: boolean,
) => Promise<CardPaymentMethodType | undefined>
export const useLinkPaymentMethod = (): UseLinkPaymentMethodType => {
  const [applyLinkPaymentMethod] = Consumer.hooks.useCardAccountLinkPaymentMethodMutation()
  const dispatch = usePfDispatch()

  /**
   * Takes a payment instrument or payment method.
   * If paymentAccountId belongs to a payment instrument, link it to make it a payment method
   * If setSelectedPayNowPaymentMethod = true, set this payment method as the selected payment method for Pay Now
   * If setPrimaryPaymentMethod = true, set this payment method as the primary payment method
   * LinkedAccounts are not supported, because they must be associated with a
   * routing and account number before they can be linked here
   */
  return async (
    paymentAccountId?: string,
    shouldSetSelectedPayNowPaymentMethod = false,
  ): Promise<CardPaymentMethodType | undefined> => {
    const user = await GetMe()
    if (!paymentAccountId || !user) return
    const cardAccountId = user?.cardAccounts?.active?.id
    const [paymentAccount, paymentAccountType] = findPaymentAccountType(paymentAccountId, user)
    let paymentMethod: CardPaymentMethodType | undefined = undefined

    switch (paymentAccountType) {
      case PaymentTypes.LinkedAccount:
        // This should never happen
        if (__DEV__) {
          throw new Error(
            'LinkedAccounts are not supported - use useSetLinkedAccountPrimaryPaymentMethod',
          )
        }
        break
      case PaymentTypes.PaymentInstrument:
        if (cardAccountId && paymentAccount) {
          const r = await applyLinkPaymentMethod({
            variables: {
              cardAccountId,
              userPaymentMethodID: paymentAccount?.id,
            },
          })
          // eslint-disable-next-line no-type-assertion/no-type-assertion
          paymentMethod = r.data?.cardAccountLinkPaymentMethod as CardPaymentMethodType | undefined
        }
        break
      case PaymentTypes.PaymentMethod:
        // eslint-disable-next-line no-type-assertion/no-type-assertion
        paymentMethod = paymentAccount as CardPaymentMethodType
        break
      case PaymentTypes.PrimaryPaymentMethod:
      default:
        // eslint-disable-next-line no-type-assertion/no-type-assertion
        paymentMethod = paymentAccount as CardPaymentMethodType
        break
    }

    if (shouldSetSelectedPayNowPaymentMethod && paymentMethod) {
      dispatch(setSelectedPayNowPaymentMethod(paymentMethod))
    }

    await dispatch(GetMeAction())
    return paymentMethod
  }
}
