import * as Types from '../../../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UpdateAddressGetOffersQueryVariables = Types.Exact<{[key: string]: never}>

export type UpdateAddressGetOffersQuery = {
  __typename?: 'Query'
  me: {
    __typename: 'User'
    id: string
    products: {
      __typename?: 'ProductsCollection'
      eligible: {
        __typename: 'ProductsOffersCollection'
        all: Array<
          | {__typename: 'CardAccountOfferInfo'}
          | {
              __typename: 'LoanOfferInfo'
              category: Types.ProductCategory
              loanOfferAvailabilityStatus: Types.LoanOfferAvailabilityStatus
              offers: Array<{__typename?: 'LoanOffer'; id: string}>
            }
        >
      }
    }
  }
}

export const UpdateAddressGetOffersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UpdateAddressGetOffers'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'products'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'eligible'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'all'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: '__typename'}},
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {kind: 'Name', value: 'LoanOfferInfo'},
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {kind: 'Field', name: {kind: 'Name', value: 'category'}},
                                        {
                                          kind: 'Field',
                                          alias: {
                                            kind: 'Name',
                                            value: 'loanOfferAvailabilityStatus',
                                          },
                                          name: {kind: 'Name', value: 'offerAvailabilityStatus'},
                                        },
                                        {
                                          kind: 'Field',
                                          name: {kind: 'Name', value: 'offers'},
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAddressGetOffersQuery, UpdateAddressGetOffersQueryVariables>
