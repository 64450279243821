import React, {useState} from 'react'
import {StackNavigationProp} from '@react-navigation/stack'
import {useTranslation} from 'react-i18next'

import {completeUra} from '@possible/cassandra'
import {
  LinkedAccountStatusCode,
  UserRequestedAction,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {ShowException} from 'src/lib/errors'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'
import Log from 'src/lib/loggingUtil'

import {MainStackParamList} from 'src/nav/MainStackParamsList'
import {usePfSelector} from 'src/store/utils'
import {PfReduxState} from 'src/reducers/types'
import {uraIds} from 'src/lib/ura/selectors'
import {UseGetLinkedAccountsResult} from 'src/lib/bank/useGetLinkedAccounts.utils'
import {useGetLinkedAccounts} from 'src/lib/bank/useGetLinkedAccounts'
import {logAddPaymentMethodErrorAndShowException} from 'src/products/general/GeneralPaymentMethods/GeneralPaymentMethods.utils'
import {useBankAggregator} from 'src/products/general/GeneralPaymentMethods/useBankAggregator'
import {useMainNavigator} from 'src/nav/AppNavHelper'

type Props = {
  uraId: string
  navigation?: StackNavigationProp<MainStackParamList, keyof MainStackParamList>
}

const RelinkAccount: React.FC<Props> = (props) => {
  const {uraId, navigation} = props
  // URA redux state needs proper typing before this can work
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
  const ura: UserRequestedAction = usePfSelector((state: PfReduxState) => uraIds(state)?.get(uraId))
  const [isCompletingUra, setIsCompletingUra] = useState<boolean>(false)
  const {t} = useTranslation('UserRequestedAction')

  const {linkedAccountsData, isLoadingLinkedAccounts} = useGetLinkedAccounts()

  const mainStackNav = useMainNavigator()
  // pass mainStackNav because it's guaranteed to exist and is required by the hook.
  // props.navigation is optional in URAs so we can't rely on it
  const {openBankAggregator} = useBankAggregator(mainStackNav)

  const close = (): void => {
    navigation?.goBack()
  }

  const handleOnBankLinkSuccess = async (): Promise<void> => {
    try {
      setIsCompletingUra(true)
      await completeUra(uraId)
      setIsCompletingUra(false)
      close()
    } catch (e) {
      ShowException(e)
    } finally {
      setIsCompletingUra(false)
    }
  }

  const getUraActionAccount =
    (): // returns either one LinkedAccount from the query inside useGetLinkedAccounts() or undefined
    | NonNullable<
          NonNullable<UseGetLinkedAccountsResult['linkedAccountsData']>['linkedAccounts']
        >[0]
      | undefined => {
      try {
        const internalAccountId = ura.actionData.accountId
        if (linkedAccountsData?.linkedAccounts) {
          return linkedAccountsData.linkedAccounts.filter(
            (account) => account.id === internalAccountId,
          )[0]
        }
        return undefined
      } catch (e) {
        Log.log(e)
      }
    }

  const onLinkAccount = (): void => {
    try {
      const account = getUraActionAccount()
      const isRelinkable =
        account &&
        account.isLoginRequired === true &&
        account.status === LinkedAccountStatusCode.LinkedInUse

      if (isRelinkable) {
        openBankAggregator({
          // pass the account to be relinked
          account: {id: account.id},
          onBankLinkComplete: handleOnBankLinkSuccess,
        })
      } else {
        // if not relinking we add a new account
        openBankAggregator({
          onBankLinkComplete: handleOnBankLinkSuccess,
        })
      }
    } catch (e) {
      logAddPaymentMethodErrorAndShowException(
        e,
        'RelinkAccount URA onLinkAccount() failed to launch aggregator',
      )
    }
  }

  const account = getUraActionAccount()
  let bodyExtra: React.ReactNode | undefined = undefined

  const accountName = account?.name ?? t('NA')
  const accountMask = account?.mask ?? 'XXXX'

  bodyExtra = (
    <PFText variant={'p_semibold'}>{t('RelinkAccountInfo', {accountName, accountMask})}</PFText>
  )

  const primary = {
    text: t('RelinkAccount'),
    disabled: isCompletingUra || isLoadingLinkedAccounts,
    onPress: (): void => {
      onLinkAccount()
    },
  }

  return (
    <URAModalContent uraId={uraId} ura={ura} extra={bodyExtra} primary={primary} close={close} />
  )
}

export {RelinkAccount}
