import {capitalize} from 'lodash'

type FormatUserNameType = {
  firstName?: string | null
  middleName?: string | null
  lastName?: string | null
  nameSuffix?: string | null
}

export const formatNameForOnboarding = (userInfo?: FormatUserNameType): string => {
  if (!userInfo) return ''
  return [
    userInfo?.firstName ?? '',
    userInfo?.middleName ?? '',
    userInfo?.lastName ?? '',
    capitalize(userInfo?.nameSuffix ?? ''),
  ].join(' ')
}
