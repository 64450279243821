import {MainStackParamList} from 'src/nav/MainStackParamsList'
import type {BackEndPreReqType, WorkflowDescriptorType} from 'src/workflows/types'

import {ApplicationActivationWorkflowDescriptor} from 'src/products/general/ApplicationActivationWorkflow/ApplicationActivationWorkflowDescriptor'

import {
  FrontEndPreReqType,
  PreReqType,
  ProductsQueryOfferTypenameType,
  WorkflowHeaderButton,
  WorkflowsStackParams,
  isBackEndPreReq,
  isFrontEndPreReq,
} from 'src/workflows/types'

export const WorkflowDescriptor: WorkflowDescriptorType = {
  /** Signup and shared */
  PHONE: {
    screen: 'PhoneConfirmation',
  },
  PHONE_VERIFICATION: {
    screen: 'PhoneConfirmation',
  },
  DOB: {
    screen: 'PersonalInformation',
  },
  FULL_NAME: {
    screen: 'PersonalInformation',
  },
  ADDRESS_HOME: {
    screen: 'AddressHome',
  },
  SSN: {
    screen: 'SSN',
    shouldExcludeFromNavigationHistory: true,
  },
  PII_CONFIRM: {
    isFrontEndPreReq: true,
    screen: 'PIIConfirm',
    associatedOfferTypenames: ['CardAccountOffer'],
    needsToBeFulfilledJustOnce: true,
    shouldExcludeFromNavigationHistory: true,
  },
  PRIMARY_ACCOUNT: {
    screen: 'PrimaryAccountSelection',
    shouldExcludeFromNavigationHistory: true,
  },
  RESIDENCE_STATE: {
    screen: 'StateSelect',
    shouldExcludeFromNavigationHistory: true,
  },

  /** Loan offers */
  AMOUNT_SELECTION: {
    screen: 'LoanAmountSelection',
    redirectToWeb: {
      platforms: ['android'],
      associatedOfferTypenames: ['LoanOffer'],
    },
  },
  DEPOSITORY_ACCOUNTS: {
    screen: 'BankLinking',
    shouldExcludeFromNavigationHistory: true,
  },
  CONFIRM_BANK_REAPPLICATION_NO_ACCOUNTS: {
    screen: 'ReapplicationBankConfirmation',
  },
  CONFIRM_BANK_REAPPLICATION_RELINK_ACCOUNT: {
    screen: 'ReapplicationBankConfirmation',
  },
  CONFIRM_BANK_REAPPLICATION_VALID_ACCOUNT: {
    screen: 'ReapplicationBankConfirmation',
  },

  MARKETING_SURVEY: {
    screen: 'MarketingSurvey',
    shouldExcludeFromNavigationHistory: true,
  },
  LOAN_APPLICATION_SUBMISSION: {
    screen: 'LoanApplicationSubmission',
    redirectToWeb: {
      platforms: ['android'],
      associatedOfferTypenames: ['LoanOffer'],
    },
  },
  EMAIL_VERIFICATION: {
    screen: 'EmailVerification',
    shouldExcludeFromNavigationHistory: true,
    redirectToWeb: {
      platforms: ['android'],
      associatedOfferTypenames: ['LoanOffer'],
    },
  },

  /** Loan activation */
  ...ApplicationActivationWorkflowDescriptor,

  /** Card offers */
  CARD_APPLICATION_SUBMISSION: {
    screen: 'CardApplicationSubmission',
  },
  CARD_APPLICATION_FINANCES: {
    screen: 'CardApplicationFinances',
  },
}

/**
 * A list of all front end prereqs that are currently implemented.
 */
export const AllSupportedFrontEndPrereqs: FrontEndPreReqType[] = []
/**
 * A list of all back end prereqs that are currently implemented.
 */
export const AllSupportedBackEndPrereqs: BackEndPreReqType[] = []

Object.keys(WorkflowDescriptor).forEach((key) => {
  if (isFrontEndPreReq(key)) {
    AllSupportedFrontEndPrereqs.push(key)
  } else if (isBackEndPreReq(key)) {
    AllSupportedBackEndPrereqs.push(key)
  }
})
const AllSupportedPrereqs: PreReqType[] = [
  ...AllSupportedFrontEndPrereqs,
  ...AllSupportedBackEndPrereqs,
]

/**
 * @summary
 * The code below basically generates constants from the `workflowsDescriptor` object.
 * Typically should not need to edit this code.
 */

/**
 *  Which MainStack routes utilize workflows
 */
export const WorkflowRoutes: (keyof MainStackParamList)[] = [
  'SignupWorkflow',
  'OfferApplicationWorkflow',
  'ApplicationActivationWorkflow',
]

/**
 * A map of an offer type to the front end pre-reqs that need to
 * be fulfilled before the offer can be selected.
 */
export const OfferTypenameToFrontEndApplicationPreReqMap: Record<
  ProductsQueryOfferTypenameType,
  FrontEndPreReqType[]
> = {
  CardAccountOffer: AllSupportedFrontEndPrereqs.filter((key) => {
    const descriptor = WorkflowDescriptor[key]
    if (
      descriptor?.isFrontEndPreReq &&
      descriptor?.associatedOfferTypenames?.includes('CardAccountOffer')
    ) {
      return true
    }
    return false
  }),
  LoanOffer: AllSupportedFrontEndPrereqs.filter((key) => {
    const descriptor = WorkflowDescriptor[key]
    if (
      descriptor?.isFrontEndPreReq &&
      descriptor?.associatedOfferTypenames?.includes('LoanOffer')
    ) {
      return true
    }
    return false
  }),
}

/**
 * A list of all front end pre-reqs that only need to be fulfilled once.
 */
export const OneTimeOnlyFrontEndPreReqs = AllSupportedFrontEndPrereqs.filter((key) => {
  const descriptor = WorkflowDescriptor[key]
  if (descriptor?.isFrontEndPreReq && descriptor?.needsToBeFulfilledJustOnce) {
    return true
  }
  return false
})

/**
 * These are routes that WILL NOT be added to the history stack when
 * the navigation gets reset.
 */
export const RoutesToRemoveFromHistory = AllSupportedPrereqs.map((key) => {
  const descriptor = WorkflowDescriptor[key]
  if (descriptor?.shouldExcludeFromNavigationHistory) {
    return descriptor.screen
  }
  return null
}).filter((route) => route !== null)

/**
 * Workflow screen header button types
 */
export const WorkflowHeaderButtonMap: Partial<
  Record<keyof WorkflowsStackParams, {left: WorkflowHeaderButton; right?: WorkflowHeaderButton}>
> = {
  Loading: {left: 'None'},
}
