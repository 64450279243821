import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Button from 'src/designSystem/components/atoms/Button/Button'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ButtonAction} from 'src/designSystem/types'

export type LoanExpiredTileProps = {
  onApplyForNewLoan: ButtonAction
  wasApproved: boolean
  isBusy?: boolean
  testID?: string
  isAdvance?: boolean
}
/**
 * A tile to inform the user that their loan has expired.
 */
const LoanExpiredTile: React.FC<LoanExpiredTileProps> = ({
  onApplyForNewLoan: handleApplyForNewLoan,
  testID,
  isBusy,
  wasApproved,
  isAdvance,
}) => {
  const {t} = useTranslation('DashboardLoanExpiredTile')

  const expiredMessage = useMemo(() => {
    if (wasApproved && isAdvance) {
      return t('WeApprovedButExpiredAdvance')
    }

    if (wasApproved && !isAdvance) {
      return t('WeApprovedButExpired')
    }

    return t('PleaseReapply')
  }, [isAdvance, t, wasApproved])

  return (
    <BasicTile padding="small" align="center" testID={testID}>
      <PFText variant="h3" textAlign="center">
        {isAdvance ? t('AdvanceHasExpired') : t('LoanHasExpired')}
      </PFText>
      <Box marginTop="small" gap="small">
        <PFText variant="p" textAlign="center">
          {expiredMessage}
        </PFText>
        {wasApproved ? (
          <PFText variant="p" textAlign="center">
            {isAdvance ? t('EligibleAmountMayVary') : t('ApprovalAmountMayVary')}
          </PFText>
        ) : null}
      </Box>
      <Box width="100%" align="center" marginTop="medium">
        <Button
          mode="primary"
          size="medium"
          width="100%"
          onPress={handleApplyForNewLoan}
          testID="LoanExpiredApplyNowBtn"
          disabled={isBusy}
          loading={isBusy}
        >
          {isAdvance ? t('GetAnotherAdvance') : t('ApplyNow')}
        </Button>
      </Box>
    </BasicTile>
  )
}

export {LoanExpiredTile}
