import React from 'react'

import AppNavActions from 'src/nav/AppNavActions'
import {SvgIconButton} from 'src/designSystem/components/molecules/SvgIconButton/SvgIconButton'

const HeaderMenuButton = (): JSX.Element => {
  return (
    <SvgIconButton
      name={'menu'}
      colorVariant={'default'}
      testID="Header-Menu-Button-Icon"
      size={'medium'}
      onPress={(): void => AppNavActions.openDrawer()}
    />
  )
}

export {HeaderMenuButton}
