import React, {FC} from 'react'

import {
  PFDescriptor,
  PFDescriptorNames,
  PFDescriptorSizes,
} from 'src/designSystem/components/atoms/PFDescriptor/PFDescriptor'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {descriptionLabel, primaryText} from 'src/designSystem/semanticColors'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {Color, SizeVariants} from 'src/designSystem/types'
import {TextVariants} from 'src/designSystem/typography'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'

export type PFDescriptionLabelSizeVariants = Extract<SizeVariants, 'small' | 'medium'>

type PFDescriptionLabelProps = {
  label: string
  labelTestID?: string
  linkText?: string
  linkOnPress?: () => void
  linkTestID?: string
  descriptor: PFDescriptorNames
  topImage?: boolean
  color?: Color
  size?: PFDescriptionLabelSizeVariants
}

const styleMap: {
  [key in PFDescriptionLabelSizeVariants]: {
    labelSize: TextVariants
    gap: SizeVariants
    descriptorSize: PFDescriptorSizes
    labelColor: Color
  }
} = {
  small: {
    labelSize: 'label_sm',
    gap: 'tiny',
    descriptorSize: 'small',
    labelColor: descriptionLabel,
  },
  medium: {
    labelSize: 'p',
    gap: 'small',
    descriptorSize: 'large',
    labelColor: primaryText,
  },
}

const PFDescriptionLabel: FC<PFDescriptionLabelProps> = (props) => {
  const {
    label,
    labelTestID,
    linkText,
    linkOnPress: handleLinkOnPress,
    linkTestID,
    descriptor,
    topImage: hasTopImage,
    color,
    size = 'small',
  } = props

  const styling = styleMap[size]

  const linkTextWithSpace = ` ${linkText}`

  return (
    <Box direction={hasTopImage ? 'column' : 'row'} gap={styling.gap} align={'center'}>
      <PFDescriptor name={descriptor} size={hasTopImage ? 45 : styling.descriptorSize} />
      <PFText color={color ?? styling.labelColor} testID={labelTestID} variant={styling.labelSize}>
        {label}
        {linkText && handleLinkOnPress ? (
          <SvgLink
            onPress={handleLinkOnPress}
            linkText={linkTextWithSpace}
            linkType={'inline'}
            testID={linkTestID}
            textVariant={styling.labelSize}
          />
        ) : null}
      </PFText>
    </Box>
  )
}

export {PFDescriptionLabel}
