import {useNavigation} from '@react-navigation/native'
import React, {useCallback} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {View} from 'react-native'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {LegalCheckbox} from 'src/designSystem/components/molecules/LegalCheckbox/LegalCheckbox'
import {displayPdf} from 'src/products/general/PDFViewer/PDFUtils'
import {getAdvanceAutoPayAgreementDocument} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/useGetAgreementDocument/useGetAgreementDocument'

export type AdvanceLegalizeProps = {
  handleSetSelected: (value: boolean) => void
  defaultPaymentMethodMask: string
  loanId: string
}

export const AdvanceLegalizeTile: React.FC<AdvanceLegalizeProps> = (props) => {
  const {handleSetSelected: onSetSelected, defaultPaymentMethodMask, loanId} = props

  const navigation = useNavigation()

  const handlePaymentAuthLink = useCallback(async () => {
    const {documentUrl} = await getAdvanceAutoPayAgreementDocument(loanId)
    displayPdf(documentUrl, navigation)
  }, [navigation, loanId])

  const [isSelected, setIsSelected] = React.useState(false)
  const {t} = useTranslation('Advance')
  return (
    <View style={{marginTop: 32}}>
      <LegalCheckbox
        onPress={() => {
          setIsSelected(!isSelected)
          onSetSelected(!isSelected)
        }}
      >
        <PFText variant="p">
          <Trans
            i18nKey={'AdvanceLegalize'}
            values={{mask: defaultPaymentMethodMask}}
            t={t}
            components={{
              PaymentAuthLink: (
                <SvgLink
                  onPress={handlePaymentAuthLink}
                  linkText={'Autopay Authorization Agreement'}
                  linkType={'inline'}
                />
              ),
            }}
          />
        </PFText>
      </LegalCheckbox>
    </View>
  )
}
