import React from 'react'
import {useTranslation} from 'react-i18next'

import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import BasicTile from 'src/designSystem/components/molecules/BasicTile/BasicTile'
import Button from 'src/designSystem/components/atoms/Button/Button'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {ButtonAction} from 'src/designSystem/types'

export type LoanPaidOffTileProps = {
  onApplyForNewLoan: ButtonAction
  reapplyOnDate: Date
  isBusy?: boolean
  testID?: string
  isAdvance?: boolean
}
/**
 * A tile to inform the user that they've paid off their loan.
 * Includes an action button to apply for a new loan.
 */
const LoanPaidOffTile: React.FC<LoanPaidOffTileProps> = ({
  onApplyForNewLoan: handleApplyForNewLoan,
  reapplyOnDate,
  isBusy,
  testID,
  isAdvance,
}) => {
  const {t} = useTranslation('DashboardLoanPaidOffTile')
  const canReapply = new Date() > reapplyOnDate
  // we show reapplyOn date + 1 day to avoid issues where it is the the reapplyOn date but the reapplyOn time is late in the day
  const reapplyOnDateNextDay = new Date(reapplyOnDate)
  reapplyOnDateNextDay.setDate(reapplyOnDateNextDay.getDate() + 1)
  const reapplyOnDateString = `${reapplyOnDateNextDay.getMonth() + 1}/${reapplyOnDateNextDay.getDate()}`

  return (
    <BasicTile padding="medium" align="center" testID={testID}>
      <PFText variant="h3" textAlign="center">
        {isAdvance ? t('YourAdvanceIsPaidOff') : t('YourLoanIsPaidOff')}
        &nbsp; &#127881; {/* <- party popper emoji */}
      </PFText>
      {!canReapply ? (
        <Box marginVertical={28}>
          <PFText variant="p">
            {t('ReapplyAgainOnDate', {
              date: reapplyOnDateString,
            })}
          </PFText>
        </Box>
      ) : null}
      <Box width="100%" align="center" marginTop={canReapply ? 'large' : 0}>
        <Button
          mode="primary"
          size="medium"
          width="100%"
          onPress={handleApplyForNewLoan}
          testID="LoanPaidOffTile-ApplyForNewLoanBtn"
          disabled={!canReapply || isBusy}
          loading={isBusy}
        >
          {isAdvance ? t('GetMyAdvance') : t('ApplyForNewLoan')}
        </Button>
      </Box>
    </BasicTile>
  )
}

export default LoanPaidOffTile
