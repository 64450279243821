import * as Types from '../../../../cassandra/src/types/types.mobile.generated'

import {TypedDocumentNode as DocumentNode} from '@graphql-typed-document-node/core'
export type UseUraPendingUserActionsQueryVariables = Types.Exact<{[key: string]: never}>

export type UseUraPendingUserActionsQuery = {
  __typename?: 'Query'
  me: {
    __typename?: 'User'
    id: string
    userRequestedActions: {
      __typename?: 'UserRequestedActionCollection'
      pending?: Array<{
        __typename?: 'UserRequestedAction'
        id: string
        userId: string
        status: Types.UserRequestedActionStatusCode
        actionType: Types.UserRequestedActionTypesCode
        actionTitle: string
        actionBody: string
        optional: boolean
        ableToCompleteAt: string
        actionData: {
          __typename?: 'UserRequestedActionData'
          accountId?: string | null
          paymentInstrumentId?: string | null
        }
        requestContext: {__typename?: 'EntityDescriptor'; type: string; id: string}
      } | null> | null
    }
  }
}

export const UseUraPendingUserActionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {kind: 'Name', value: 'UseUraPendingUserActions'},
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {kind: 'Name', value: 'me'},
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                {
                  kind: 'Field',
                  name: {kind: 'Name', value: 'userRequestedActions'},
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {kind: 'Name', value: 'pending'},
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'userId'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'status'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'actionType'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'actionTitle'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'actionBody'}},
                            {kind: 'Field', name: {kind: 'Name', value: 'optional'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'actionData'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'accountId'}},
                                  {
                                    kind: 'Field',
                                    name: {kind: 'Name', value: 'paymentInstrumentId'},
                                  },
                                ],
                              },
                            },
                            {kind: 'Field', name: {kind: 'Name', value: 'ableToCompleteAt'}},
                            {
                              kind: 'Field',
                              name: {kind: 'Name', value: 'requestContext'},
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {kind: 'Field', name: {kind: 'Name', value: 'type'}},
                                  {kind: 'Field', name: {kind: 'Name', value: 'id'}},
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseUraPendingUserActionsQuery, UseUraPendingUserActionsQueryVariables>
