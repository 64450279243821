import React, {FC, useCallback, useEffect} from 'react'
import {useFocusEffect} from '@react-navigation/native'
import {useTranslation} from 'react-i18next'
import {StackScreenProps} from '@react-navigation/stack'

import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {
  TrackAppEvent,
  CardApproved as CardApprovedMarketingEvent,
} from 'src/lib/Analytics/analytics_compat'
import {CardApprovedTemplate} from 'src/products/card/Activation/CardApproved/CardApprovedTemplate/CardApprovedTemplate'
import {Consumer} from '@possible/cassandra'
import {WorkflowScreenProps} from 'src/workflows/types'
import {BaseTemplate} from 'src/products/general/components/templates/BaseTemplate/BaseTemplate'
import {HeaderButtonOptionFactory} from 'src/workflows/buttons'
import {MainStackParamList} from 'src/nav/MainStackParamsList'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {useIsInCardBureauFlow} from 'src/products/card/Application/useIsInCardBureauFlow'

type Props = WorkflowScreenProps &
  Pick<StackScreenProps<MainStackParamList>, 'navigation' | 'route'>
const CardApprovedWorkflowContainer: FC<Props> = ({onScreenComplete, navigation, route}) => {
  const {t} = useTranslation('CardDashboard')
  const {
    data,
    error,
    loading: isLoading,
    refetch: handleOnRetry,
  } = Consumer.hooks.useCardActivationApprovedQuery({
    fetchPolicy: 'cache-first',
  })
  const {isInCardBureauFlow} = useIsInCardBureauFlow()

  usePageViewedAnalytics({
    eventName: AppEvents.Name.card_approved_page_viewed,
    eventCategory: AppEvents.Category.Onboarding,
  })

  useFocusEffect(
    useCallback(() => {
      CardApprovedMarketingEvent()
    }, []),
  )

  useEffect(() => {
    const Logout = HeaderButtonOptionFactory({headerButton: 'Logout', navigation, route})
    navigation.setOptions({
      headerShown: true,
      header: () => (
        <Box paddingHorizontal="medium" paddingVertical="small" direction="row">
          {Logout ? Logout({}) : null}
        </Box>
      ),
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnPress = (): void => {
    TrackAppEvent(AppEvents.Name.card_approved_page_cta, AppEvents.Category.Onboarding)
    onScreenComplete()
  }

  let creditLimit: string = ''
  let monthlyMembershipFees: string = ''

  if (
    data?.me.cardAccounts.active?.creditLimit &&
    data?.me.cardAccounts.active?.cardAccountType?.monthlyMembershipFees
  ) {
    creditLimit = data?.me.cardAccounts.active?.creditLimit
    monthlyMembershipFees = data?.me.cardAccounts.active?.cardAccountType?.monthlyMembershipFees
  }

  return (
    <BaseTemplate
      isError={!!error || !creditLimit || !monthlyMembershipFees}
      isLoading={isLoading}
      onRetry={handleOnRetry}
      errorTitle={t('ErrorTitle')}
    >
      <CardApprovedTemplate
        creditLimit={creditLimit}
        feeAmount={monthlyMembershipFees}
        onPress={handleOnPress}
        isInCardBureauFlow={isInCardBureauFlow}
      />
    </BaseTemplate>
  )
}

export {CardApprovedWorkflowContainer}
