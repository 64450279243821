import React, {ReactNode} from 'react'
import {Calendar, CalendarProps} from 'react-native-calendars'
import {Direction, MarkedDates, Theme} from 'react-native-calendars/src/types'

import {selectedDateColor, disabledText} from 'src/designSystem/semanticColors'
import {SvgIcon} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon'
import {getTestID} from 'src/lib/utils/tests.utils'

export type ReschedulePaymentCalendarProps = {
  markedDates: MarkedDates
  maxDate: CalendarProps['maxDate']
  minDate: CalendarProps['minDate']
  onDayPress: CalendarProps['onDayPress']
  selectedDate: CalendarProps['current']
}

const calendarTheme: Theme = {
  selectedDayBackgroundColor: selectedDateColor,
  dotColor: selectedDateColor,
  textDisabledColor: disabledText,
  textMonthFontWeight: 'bold',
}

const renderArrow = (direction: Direction): ReactNode => {
  const testID = getTestID('calendar-arrow', direction)
  return (
    <SvgIcon
      name={direction === 'left' ? 'chevronLeft' : 'chevronRight'}
      colorVariant={'info'}
      testID={testID}
    />
  )
}

const ReschedulePaymentCalendar = (props: ReschedulePaymentCalendarProps): JSX.Element => {
  const {onDayPress: handleOnDayPress, markedDates, maxDate, minDate, selectedDate} = props
  return (
    <Calendar
      testID="ReschedulePaymentCalendar"
      theme={calendarTheme}
      key={selectedDate}
      current={selectedDate}
      renderArrow={renderArrow}
      minDate={minDate}
      maxDate={maxDate}
      markedDates={markedDates}
      onDayPress={handleOnDayPress}
    />
  )
}

export {ReschedulePaymentCalendar}
