import React, {FC} from 'react'
import {TouchableOpacity} from 'react-native'
import {useTranslation} from 'react-i18next'

import {Color} from 'src/designSystem/types'
import {ContextualizedLogException} from 'src/lib/errors'
import {DefaultVariantsColor} from 'src/designSystem/colors'
import {Logout} from 'src/api/MobileGatewayAPI/actions/logout'
import {usePfDispatch} from 'src/store/utils'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'

type Props = {
  color?: Color
}

const HeaderLogoutButton: FC<Props> = (props: Props) => {
  const {color} = props
  const {t} = useTranslation('Common')
  const dispatch = usePfDispatch()

  const handleLogout = (): void => {
    dispatch(Logout(false)).catch(
      ContextualizedLogException('HeaderLogoutButton: Failed to log out'),
    )
  }
  return (
    <TouchableOpacity
      accessibilityRole="button"
      activeOpacity={0.8}
      onPress={handleLogout}
      style={{alignItems: 'center'}}
      testID={'Logout-Button'}
    >
      <PFText
        variant={'label_md'}
        color={color ?? DefaultVariantsColor.link}
        testID={'Logout-Button-Text'}
      >
        {t('Logout')}
      </PFText>
    </TouchableOpacity>
  )
}

export {HeaderLogoutButton}
