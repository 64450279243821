import {LoanDocumentType} from '@possible/cassandra/src/types/types.mobile.generated'
import {ApplyMutation} from '@possible/cassandra/src/utils/operations'

import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {logOfferActivationErrorAndShowException} from 'src/products/general/OfferActivationWorkflow/OfferActivation.utils'
import {AcceptAgreementsGetAgreementDocUrlDocument} from 'src/products/loans/LoanApprovedActivation/AcceptAgreements/useGetAgreementDocument/useGetAgreementDocument.gqls'

/**
 * Get any loan document url.
 */
const getAgreementDocument = async (args: {
  loanId: string
  type: LoanDocumentType
}): Promise<{documentUrl: string}> => {
  const {loanId, type} = args
  try {
    const res = await ApplyMutation(AcceptAgreementsGetAgreementDocUrlDocument, {
      loanId,
      type,
    })

    const documentUrl = res?.loanGetDocument?.url
    if (!documentUrl) {
      throw new Error('no document url returned by the server')
    }
    return {
      documentUrl,
    }
  } catch (e) {
    logOfferActivationErrorAndShowException(
      e,
      `Failed get agreement document url for loanId=${loanId} documentType=${type}`,
    )
    throw e
  }
}

/**
 * Get the loan autopay agreement document url.
 */
export const getLoanAutoPayAgreementDocument = async (args: {
  loanId: string
}): Promise<{documentUrl: string}> => {
  const {loanId} = args
  TrackAppEvent(
    AppEvents.Name.accept_agreements_view_ach_agreement_selected,
    AppEvents.Category.Activation,
  )
  return await getAgreementDocument({
    loanId,
    type: LoanDocumentType.AchAgreement,
  })
}

/**
 * Get the loan agreement document url.
 */
export const getLoanAgreementDocument = async (args: {
  loanId: string
  isSinglePaymentAgreement?: boolean
  isExtendedInstallmentPlan?: boolean
}): Promise<{documentUrl: string}> => {
  const {loanId, isSinglePaymentAgreement} = args
  TrackAppEvent(
    AppEvents.Name.accept_agreements_view_loan_agreement_selected,
    AppEvents.Category.Activation,
  )
  let documentType: LoanDocumentType = LoanDocumentType.LoanAgreement
  if (isSinglePaymentAgreement) {
    documentType = LoanDocumentType.SinglePaymentLoanAgreement
  } else if (args.isExtendedInstallmentPlan) {
    documentType = LoanDocumentType.InstallmentLoanAgreement
  }

  return await getAgreementDocument({
    loanId,
    type: documentType,
  })
}

export const getSubscriptionAutoPayAgreementDocument = async (
  loanId: string,
): Promise<{documentUrl: string}> => {
  return getAgreementDocument({
    loanId,
    type: LoanDocumentType.AchMembershipFeeAgreement,
  })
}

export const getAdvanceAutoPayAgreementDocument = async (
  loanId: string,
): Promise<{documentUrl: string}> => {
  return getAgreementDocument({
    loanId,
    type: LoanDocumentType.AchAgreement,
  })
}

export const useGetAgreementDocument = (): {
  getLoanAgreementDocument: typeof getLoanAgreementDocument
  getLoanAutoPayAgreementDocument: typeof getLoanAutoPayAgreementDocument
  getAgreementDocument: typeof getAgreementDocument
  getSubscriptionAutoPayAgreementDocument: typeof getSubscriptionAutoPayAgreementDocument
  getAdvanceAutoPayAgreementDocument: typeof getAdvanceAutoPayAgreementDocument
} => {
  // note: this isn't stateful right now so doesn't technically need to be a hook but
  // by making it a hook now we will be able to do things like add loading/submitting state
  // later if we want to without having to change the API
  return {
    getLoanAutoPayAgreementDocument,
    getLoanAgreementDocument,
    getAgreementDocument,
    getSubscriptionAutoPayAgreementDocument,
    getAdvanceAutoPayAgreementDocument,
  }
}
