import {GetMeAction} from '@possible/cassandra'
import {
  UserModifyProfileDocument,
  UserModifyProfileMutationVariables,
} from '@possible/cassandra/src/types/types.mobile.generated'
import {ApplyMutation} from '@possible/cassandra/src/utils/operations'

import Log from 'src/lib/loggingUtil'
import {formatDate, graphqlDateFormat} from 'src/lib/utils/date'

//////////// User Actions

interface UserInfoUpdate {
  firstName: string
  lastName: string
  middleName: string
  nameSuffix: string
  birthDate: string
  ssn: string
}

const _transformUserInfo = (
  userInfo: Partial<UserInfoUpdate>,
): UserModifyProfileMutationVariables => {
  const info: UserModifyProfileMutationVariables = {}

  if (userInfo.firstName && userInfo.lastName) {
    info.name = {
      first: userInfo.firstName,
      last: userInfo.lastName,
      middle: userInfo.middleName,
      suffix: userInfo.nameSuffix,
    }
  }

  if (userInfo.birthDate) {
    info.dob = {dob: formatDate(userInfo.birthDate, graphqlDateFormat)}
  }

  if (userInfo.ssn) {
    info.ssn = {ssn: userInfo.ssn}
  }

  return info
}

export function userInfoUpdate(userInfo: Partial<UserInfoUpdate>) {
  return async (dispatch) => {
    try {
      const response = await ApplyMutation(UserModifyProfileDocument, _transformUserInfo(userInfo))

      if (!response) {
        throw new Error('Failed to update user info')
      }
      await dispatch(GetMeAction())

      return response['userModifyProfile']
    } catch (e) {
      Log.error(e, 'userInfoUpdate error:')
      throw e
    }
  }
}
