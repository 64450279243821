import React, {useState, useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {get_ura_by_id_from_redux} from 'src/lib/ura/ura.utils'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'
import {AppEvents} from 'src/lib/Analytics/app_events'
import {URAProps} from 'src/products/general/UserRequestedActions/URAProps'
import {completeUra} from 'src/cassandra'
import {ShowException} from 'src/lib/errors'
import {usePfDispatch, usePfSelector} from 'src/store/utils'
import URAModalContent from 'src/products/general/UserRequestedActions/URAModalContent'
import PFText from 'src/designSystem/components/atoms/PFText/PFText'
import PFCheckbox from 'src/designSystem/components/atoms/PFCheckbox/PFCheckbox'
import Box from 'src/designSystem/components/atoms/Box/Box'
import {SvgLink} from 'src/designSystem/components/atoms/SvgLink/SvgLink'
import {OpenWebPage} from 'src/navigation/NavHelper'

export const UnfreezeBureauFrozenFile: React.FunctionComponent<URAProps> = (props: URAProps) => {
  const {componentId, ura_id: uraId, close} = props

  const [isCompleting, setIsCompleting] = useState(false)
  const [hasWaited, setHasWaited] = useState(false)
  const [isCertifiedUnfrozen, setIsCertifiedUnfrozen] = useState(false)

  const {t} = useTranslation('UserRequestedAction')
  const dispatch = usePfDispatch()

  const ura = usePfSelector((state) => get_ura_by_id_from_redux(uraId, state))

  useEffect(() => {
    TrackAppEvent(
      AppEvents.Name.unfreeze_bureau_frozen_file_URA_viewed,
      AppEvents.Category.Application,
    )
  }, [dispatch, uraId, componentId, close])

  // This useEffect checks every minute until isAbleToComplete is true, and sets hasWaited to true
  useEffect(() => {
    // If field is not set, don't run the check
    if (!ura?.ableToCompleteAt) {
      setHasWaited(true)
      return
    }

    // Check if current time is after ableToCompleteAt timestamp with a 10 second buffer
    const isAbleToComplete = (): boolean =>
      new Date() > new Date(new Date(ura?.ableToCompleteAt).getTime() + 10 * 1000)

    if (isAbleToComplete()) {
      setHasWaited(true)
      return
    }

    // If user must wait longer, check every minute until isAbleToComplete is true
    const interval = setInterval(() => {
      if (isAbleToComplete()) {
        setHasWaited(true)
        clearInterval(interval)
      }
    }, 60 * 1000)

    return (): void => clearInterval(interval)
  }, [ura?.ableToCompleteAt])

  // checks if current time is after ableToCompleteAt timestamp, waits 60 seconds after ableToCompleteAt timestamp

  const onComplete = async (): Promise<void> => {
    try {
      TrackAppEvent(
        AppEvents.Name.unfreeze_bureau_frozen_file_URA_completed,
        AppEvents.Category.Application,
      )
      setIsCompleting(true)
      await completeUra(uraId)
      close()
    } catch (e) {
      ShowException(e)
    }
  }

  const primary = {
    text: t('ContinueApplication'),
    onPress: onComplete,
    disabled: isCompleting || !hasWaited || !isCertifiedUnfrozen,
    loading: isCompleting,
  }

  const extra = (
    <>
      <Box marginTop="tiny">
        <PFText variant="p" textAlign="center">
          <Trans i18nKey="UserRequestedAction:VisitTransUnionOrExperian">
            <PFText variant="p_semibold">Visit:</PFText>{' '}
            <SvgLink
              onPress={(): void => OpenWebPage({uri: 'https://www.transunion.com/credit-freeze'})}
              textVariant={'p_semibold'}
              linkText={'TransUnion'}
              linkType={'inline'}
            />{' '}
            and/or{' '}
            <SvgLink
              onPress={(): void =>
                OpenWebPage({uri: 'https://www.experian.com/freeze/center.html'})
              }
              textVariant={'p_semibold'}
              linkText={'Experian'}
              linkType={'inline'}
            />
          </Trans>
        </PFText>
      </Box>
      <Box marginTop="small" marginBottom="tiny">
        <PFCheckbox
          checked={isCertifiedUnfrozen}
          onPress={(certify): void => setIsCertifiedUnfrozen(certify)}
          disabled={!hasWaited}
          testID="UnfreezeBureauFrozenFile-CertifyUnfrozenCheckbox"
        >
          {t('CertifyUnfrozen')}
        </PFCheckbox>
      </Box>
    </>
  )

  return (
    <URAModalContent
      uraId={uraId}
      ura={ura}
      primary={primary}
      close={close}
      extra={extra}
      legalText={t('FreezeDisclaimer')}
    />
  )
}
