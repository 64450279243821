import React, {FC, useRef} from 'react'
import {Control, FieldErrors} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {StyleSheet, View} from 'react-native'

import {Consumer} from '@possible/cassandra'
import {BoxProps} from 'src/designSystem/components/atoms/Box/Box'
import HookForm, {
  DefaultFormGapSizeVariant,
  FieldVariants,
  FormFieldProps,
} from 'src/designSystem/components/atoms/HookForm/HookForm'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {InputRef} from 'src/designSystem/components/atoms/PFTextInput/Input'
import {StateDropdown} from 'src/designSystem/components/molecules/StateDropdown/StateDropdown'
import {gapSizeMap} from 'src/designSystem/layout'
import {shortZipCodeLength, validShortZipCode} from 'src/lib/utils/formValidationUtil'

export const RequiredAddressFields = ['street1', 'city', 'state', 'postalCode']

type Props = {
  control: Control<Consumer.types.ApplicantPhysicalAddressComponentsInput>
  errors: FieldErrors
  hasSelectedState?: boolean
  canUpdateState?: boolean
}

const AddressForm: FC<Props> = (props) => {
  const {control, errors, hasSelectedState, canUpdateState = false} = props
  const {t}: {t: (key: string, options?: Record<string, unknown>) => string} =
    useTranslation('Common')

  const streetAddress2: InputRef = useRef(null)
  const city: InputRef = useRef(null)
  const zipcode: InputRef = useRef(null)

  const formProps: {[x: string]: FormFieldProps} = {
    street1: {
      name: 'street1',
      field: FieldVariants.TextField,
      rules: {
        required: t('LabelIsRequired', {label: t('StreetAddress1')}),
      },
      viewStyle: styles.fullRow,
    },
    street2: {
      name: 'street2',
      field: FieldVariants.TextField,
      viewStyle: styles.fullRow,
      ref: streetAddress2,
    },
    city: {
      name: 'city',
      field: FieldVariants.TextField,
      rules: {
        required: t('LabelIsRequired', {label: t('City')}),
      },
      viewStyle: styles.fullRow,
      ref: city,
    },
    state: {
      name: 'state',
      field: FieldVariants.Dropdown,
      rules: {
        required: t('LabelIsRequired', {label: t('State')}),
      },
      viewStyle: styles.splitRowItemLeft,
    },
    postalCode: {
      name: 'postalCode',
      field: FieldVariants.TextField,
      rules: {
        required: t('LabelIsRequired', {label: t('ZipCode')}),
        validate: (value) => {
          return validShortZipCode(value)
            ? true
            : t('ZipCodeNumericError', {length: shortZipCodeLength})
        },
      },
      viewStyle: styles.splitRowItemRight,
      ref: zipcode,
    },
  }

  //this box is a bit of an exception to our normal forms because two of the fields are on the same row
  //this is overriding the default gap in HookForm and adding the same padding with styles in formProps
  const boxProps: BoxProps = {
    gap: 'none',
    direction: 'row',
    wrap: 'wrap',
  }

  const stateField =
    !hasSelectedState || canUpdateState ? (
      <StateDropdown
        label={t('State')}
        formProps={formProps.state}
        useValueForLabel={true}
        testID="Address-Form-State"
      />
    ) : (
      <PFTextInput
        label={t('State')}
        formProps={formProps.state}
        testID="Address-Form-State"
        editable={false}
      />
    )

  return (
    // eslint-disable-next-line no-type-assertion/no-type-assertion
    <HookForm control={control as unknown as Control} errors={errors} box={boxProps}>
      <PFTextInput
        label={t('StreetAddress1')}
        formProps={formProps.street1}
        returnKeyType={'next'}
        onSubmitEditing={(): void => streetAddress2.current?.focus()}
        textContentType="streetAddressLine1"
        testID="Address-Form-Street1"
      />
      <PFTextInput
        label={t('StreetAddress2')}
        formProps={formProps.street2}
        returnKeyType={'next'}
        onSubmitEditing={(): void => city.current?.focus()}
        textContentType="streetAddressLine2"
        testID="Address-Form-Street2"
      />
      <PFTextInput
        label={t('City')}
        formProps={formProps.city}
        returnKeyType={'next'}
        onSubmitEditing={(): void => zipcode.current?.focus()}
        textContentType="addressCity"
        testID="Address-Form-City"
      />
      <View style={styles.stateZipGroup}>
        <View style={styles.stateZipGroupItem}>{stateField}</View>
        <View style={styles.stateZipGroupItem}>
          <PFTextInput
            label={t('ZipCode')}
            keyboardType="numeric"
            formProps={formProps.postalCode}
            maxLength={shortZipCodeLength}
            textContentType="postalCode"
            testID="Address-Form-Zip"
          />
        </View>
      </View>
    </HookForm>
  )
}
export {AddressForm}

const styles = StyleSheet.create({
  fullRow: {
    paddingBottom: gapSizeMap[DefaultFormGapSizeVariant],
    width: '100%',
  },
  splitRowItemLeft: {
    paddingBottom: gapSizeMap[DefaultFormGapSizeVariant],
    paddingRight: 2,
  },
  splitRowItemRight: {
    paddingBottom: gapSizeMap[DefaultFormGapSizeVariant],
  },
  stateZipGroup: {
    flexDirection: 'row',
    width: '100%',
  },
  stateZipGroupItem: {
    width: '50%',
  },
})
