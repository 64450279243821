import React, {useCallback} from 'react'
import {useTranslation} from 'react-i18next'

import {ButtonLockupProps} from 'src/designSystem/components/molecules/ButtonLockup/ButtonLockup'
import Page from 'src/designSystem/components/organisms/Page/Page'
import Log from 'src/lib/loggingUtil'
import {AdvanceLegalizeTile} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptAdvanceAutoPayAgreements/AdvanceLegalizeTile'
import {
  PaymentInfoTile,
  PaymentInfoTileProps,
} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptAdvanceAutoPayAgreements/PaymentInfoTile'
import {MembershipTile} from 'src/products/loans/LoanApprovedActivation/Advance/AcceptAdvanceAutoPayAgreements/MembershipTile'
import {usePageViewedAnalytics} from 'src/lib/Analytics/usePageViewedAnalytics'
import {AppEvents, CashAdvanceEvents} from 'src/lib/Analytics/app_events'
import {TrackAppEvent} from 'src/lib/Analytics/analytics_compat'

export type AcceptAdvanceAutoPayAgreementsTemplateProps = {
  amountApproved: string | undefined
  paymentInfo: PaymentInfoTileProps
  onAcceptAutoPay: () => Promise<void>
  loanId: string
}

export const AcceptAdvanceAutoPayAgreementsTemplate: React.FC<
  AcceptAdvanceAutoPayAgreementsTemplateProps
> = (props) => {
  const {onAcceptAutoPay: onAcceptAutoPay, loanId} = props
  const {t} = useTranslation('Advance')
  const [isBusy, setIsBusy] = React.useState(false)
  const [isSelected, setIsSelected] = React.useState(false)

  const {amountApproved, paymentInfo} = props

  usePageViewedAnalytics({
    eventName: CashAdvanceEvents.advance_schedule_viewed,
    eventCategory: AppEvents.Category.CashAdvance,
  })

  const handleContinue = useCallback(async () => {
    try {
      setIsBusy(true)
      TrackAppEvent(CashAdvanceEvents.advance_schedule_completed, AppEvents.Category.CashAdvance)
      await onAcceptAutoPay()
    } catch (e) {
      Log.error(e, 'AcceptAdvanceAutoPayAgreementsTemplate exception : ')
    } finally {
      setIsBusy(false)
    }
  }, [onAcceptAutoPay])

  const buttonLockupProps: ButtonLockupProps = {
    type: 'singleButton',
    primary: {
      text: 'Continue',
      onPress: handleContinue,
      disabled: !isSelected || isBusy,
    },
  }

  return (
    <Page
      title={t('GetYourAdvance')}
      titleTextAlignment="left"
      description={t('ReschedulePaymentDateAndPayInFour')}
      buttonProps={buttonLockupProps}
      variant={'generic'}
      smallTopGap
    >
      <MembershipTile amountApproved={amountApproved} />
      <PaymentInfoTile {...paymentInfo} />
      <AdvanceLegalizeTile
        handleSetSelected={setIsSelected}
        defaultPaymentMethodMask={paymentInfo?.accountMask}
        loanId={loanId}
      />
    </Page>
  )
}
