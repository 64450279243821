import React, {FC, forwardRef, useState, useCallback, useMemo, ForwardedRef} from 'react'
import {createPortal} from 'react-dom'
import {TextInput, View} from 'react-native'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import {useTranslation} from 'react-i18next'
import {omit} from 'lodash'

import 'src/designSystem/components/molecules/PFDatePickerField/datepicker-overrides.css'
import {DateToStringFormat} from 'src/api/lib/APIUtils'
import PFTextInput from 'src/designSystem/components/atoms/PFTextInput'
import {
  getOpenToDate,
  onSelect,
} from 'src/designSystem/components/molecules/PFDatePickerField/PFDatePickerField.utils'
import {PFDatePickerFieldProps} from 'src/designSystem/components/molecules/PFDatePickerField/PFDatePickerField.types'

const PFDatePickerField: FC<PFDatePickerFieldProps> = (props) => {
  const {t} = useTranslation('Common')
  const {onChange, label, minimumDate, maximumDate, value, ...otherProps} = props
  const accessibilityAndPrivacyProps = omit(otherProps, ['format', 'formProps'])
  const [selectedDate, setSelectedDate] = useState<moment.Moment | undefined>(value)

  const [isEmptyAfterFocus, setIsEmptyAfterFocus] = useState(false)

  const handleOnUpdateValue = useCallback(
    (newDate: Date | null) => {
      if (newDate) {
        setIsEmptyAfterFocus(false)
      }
      let momentDate: moment.Moment | undefined = undefined
      if (newDate instanceof Date) {
        momentDate = moment(newDate)
      }

      setSelectedDate(momentDate)
      onSelect(momentDate, onChange)
    },
    [onChange],
  )

  const datePicker = useMemo(() => {
    // https://reactdatepicker.com/#example-custom-input
    type CustomInputProps = {
      onClick?: () => void
    }
    const CustomInput: FC<CustomInputProps> = forwardRef(
      // eslint-disable-next-line react/prop-types
      ({onClick = (): void => undefined}, ref: ForwardedRef<TextInput>) => {
        return (
          <PFTextInput
            {...accessibilityAndPrivacyProps} // Order matters! `privacyProps` needs to be after `testID`
            ref={ref}
            label={label}
            // normally we'd do errorOnEmpty={true} to show an error when there is no value in a field, but
            // when you interact with the calendar UI the PFTextInput's onBlur() is called since you unfocused the input
            // and focused the calendar instead. that makes PFTextInput think its value is empty before you've had a
            // chance to pick a value in the calendar UI so it would show the "required" error prematurely
            error={isEmptyAfterFocus ? t('LabelIsRequired', {label}) : undefined}
            value={DateToStringFormat(selectedDate)}
            onPress={(): void => onClick()}
          />
        )
      },
    )

    CustomInput.displayName = 'CustomInput'

    return (
      <DatePicker
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        selected={getOpenToDate({value: selectedDate, minimumDate, maximumDate})}
        onChange={handleOnUpdateValue}
        onClickOutside={(): void => {
          // if the user focuses the calendar and then clicks outside to unfocus
          // without choosing a value we want to know so we can show an error
          if (!selectedDate) {
            setIsEmptyAfterFocus(true)
          }
        }}
        customInput={<CustomInput />}
        minDate={minimumDate}
        maxDate={maximumDate}
        popperContainer={
          ({children}): React.ReactPortal =>
            createPortal(<View {...accessibilityAndPrivacyProps}>{children}</View>, document.body) // Order matters! `privacyProps` needs to be after `testID`
        }
      />
    )
  }, [maximumDate, minimumDate, selectedDate, label, isEmptyAfterFocus]) // eslint-disable-line react-hooks/exhaustive-deps

  return datePicker
}

export default PFDatePickerField
