import React, {useEffect, useState} from 'react'
import {Pressable} from 'react-native'
import {StackHeaderProps} from '@react-navigation/stack'

import {Color} from 'src/designSystem/types'
import {DefaultVariantsColor} from 'src/designSystem/colors'
import {SvgIconButtonProps} from 'src/designSystem/components/molecules/SvgIconButton/SvgIconButton'
import PFSvg from 'src/designSystem/components/atoms/PFSvg/PFSvg'
import {
  iconColorOptions,
  SvgIconsFull,
  SvgIconSizeMap,
} from 'src/designSystem/components/atoms/SvgIcon/SvgIcon.utils'

type HeaderBackButtonPropTypes = Pick<StackHeaderProps, 'navigation'> &
  Pick<SvgIconButtonProps, 'size'> & {
    color?: Color
    onPress?: () => void
    testID?: string
  }

const HeaderBackButton = (props: HeaderBackButtonPropTypes): JSX.Element => {
  const {navigation, color = DefaultVariantsColor.black, onPress: handleOnPress, testID} = props
  const handleDefaultOnPress = (): void => navigation.goBack()

  const [selectedColor, setSelectedColor] = useState<Color>(color)

  // if color changes as a prop, it's not reflected if passed directly into useState
  useEffect(() => {
    setSelectedColor(color)
  }, [color])

  const handleOnIconPress = (): void => {
    setSelectedColor(iconColorOptions['inactive'].color)
  }

  const handleOnIconRelease = (): void => {
    setSelectedColor(color ?? DefaultVariantsColor.black)
  }

  const size = SvgIconSizeMap['large']

  return (
    <Pressable
      hitSlop={size * 2}
      onPressIn={handleOnIconPress}
      onPressOut={handleOnIconRelease}
      onLongPress={handleOnIconRelease}
      onPress={handleOnPress ? handleOnPress : handleDefaultOnPress}
      accessibilityLabel={'Header Back Button'}
      testID={testID ?? 'HeaderBackButton-Button'}
    >
      <PFSvg
        testID={'HeaderBackButton-Svg'}
        SvgUri={SvgIconsFull['arrowLeft'].default}
        svgProps={{
          height: size,
          width: size,
          fill: selectedColor,
        }}
      />
    </Pressable>
  )
}

export {HeaderBackButton}
